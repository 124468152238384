/**
 * Static texts for Savings Robot.
 */
export const RobotTextResources = {
  primary: {
    NORNE: "Norne",
    TEXT_LANGUAGE_A: "Bokmål",
    TEXT_LANGUAGE_B: "Nynorsk",
    TEXT_YES: "JA",
    TEXT_NO: "NEI",
    TEXT_CLOSE: "Lukk",
    TEXT_QUESTION: "Hvorfor spør vi om dette?",

    BUTTON_NEXT: "Neste",
    BUTTON_BACK: "Tilbake",
    BUTTON_START: "Start",
    BUTTON_START_SAVING: "Start spareroboten",
    BUTTON_COMPLETE_SAVING: "Start sparing",
    BUTTON_INFO: "Mer info",
    BUTTON_LESS_INFO: "Mindre info",
    BUTTON_FINISH: "Skriv ut oppsummering",
    BUTTON_RETURN: "Tilbake til min oversikt",
    BUTTON_LOG_IN: "Logg inn og fortsett",

    VALUE_OPTIMISTIC: "Optimistisk verdi",
    VALUE_EXPECTED: "Forventet verdi",
    VALUE_CONSERVATIVE: "Konservativ verdi",
    AMOUNT_DEPOSITED: "Innskutt beløp",
    NOK: "NOK",
    SHARES: "Aksjer",
    INTEREST: "Renter",

    // Validation
    VALIDATION_NO_SELECTION: "Før du går videre må du velge et alternativ",
    VALIDATION_MIN_AMOUNT: "Minimum månedlig sparebeløp er 250 NOK",
    VALIDATION_START_AMOUNT: "Oppstartsbeløp må være høyere enn 250 NOK, eller 0 NOK",
    VALIDATION_RETURNING: "Du har tidligere verdier lagret. Ønsker du å fortsette med dem, eller starte på nytt?",

    // User messages
    MESSAGE_NO_FUND_FOR_YOU: "Fondssparing er nok ikke det beste alternativet for deg.",
    MESSAGE_REMOVE_DEBT: "Vi anbefaler at du betaler ned på gjeld før du starter fondssparing",
    MESSAGE_CREDIT:
      "Med dyr gjeld som ikke er planlagt nedbetalt kan vi ikke gi deg råd om å spare i fond fremfor nedbetaling. Oppdater dine valg eller avslutt.",
    MESSAGE_CHANGE_REPLY: "Endre svar",
    MESSAGE_CONTINUE: "Fortsett",
    MESSAGE_RESTART: "Start på nytt",
    MESSAGE_QUIT: "Avslutt sparerobot",
    MESSAGE_LOADING: "Laster data...",

    // Error
    ERROR: "Noe gikk galt",
    ERROR_FETCH: "Klarte ikke å hente ut spareforslag",
    ERROR_CONTINUE: "Kan ikke gå videre uten forslag",
    ERROR_COST: "Klarte ikke å hente ut kostnader for dette spareforslaget",
    ERROR_ACCOUNT: "Klarte ikke å hente ut konto",
    ERROR_AGREEMENT: "Klarte ikke å opprette spareavtalen. Kontakt en rådgiver",

    // Startpage
    PAGE_0_HEADER: "Velkommen til spareroboten",
    PAGE_0_SUBHEADER:
      "Sparingen du velger å starte kan når som helst stoppes, og pengene tas ut. Ingen sparing blir startet før du i siste steg blir bedt om endelig godkjenning.",
    PAGE_0_SUBHEADER_2:
      "Robotrådgiveren skal autoriseres av Finansnæringens autorisasjonsordninger innen juli 2024. Autorisasjonen skal gi en trygghet for at løsningen ikke bare oppfyller lovkrav, men også finansnæringens bransjenorm God Skikk. Les mer her: ",
    PAGE_0_BUTTON_TEXT_ACTIVE: "Mer info",
    PAGE_0_INFO_TEXT_A: "Du kan når som helst gå tilbake og endre tidligere svar",
    PAGE_0_INFO_TEXT_H: "Om du vil velge fond selv avslutter du roboten og logger på bankens fondsplattform",
    PAGE_0_INFO_TEXT_B: "Blir du usikker kan du alltid ringe og få snakke med en rådgiver.",
    PAGE_0_INFO_TEXT_C:
      "Dersom opplysningene du oppgir er ufullstendige eller uriktige vil du kunne få spareråd som ikke er egnet for deg",
    PAGE_0_INFO_TEXT_D:
      "Investeringsådene i form av spareavtale og engangstegning i fond i forskjellige sammensetninger, er personlige, ikke-uavhengige råd. Endelig produktanbefaling er basert på utvalget av fond (150) hos Norne - herunder både aktive og passive aksjefond, kombinasjonsfond og rentefond",
    PAGE_0_INFO_TEXT_E:
      "Ønsker du risikofri sparing, eller har kredittkort eller forbrukslån med høye renter, bør du ikke spare i fond.",
    PAGE_0_INFO_TEXT_F:
      "Sparerobotens automatiserte råd er basert på informasjonen du oppgir. Personopplysninger benyttes ikke til profilering. Norne innhenter ikke informasjon om deg som kunde fra andre datakilder.",
    PAGE_0_INFO_TEXT_G:
      "Det foretas ikke årlig egnethetsvurdering av rådene som blir gitt. Senere endring av sparingen skjer på eget initiativ.",
    PAGE_0_INFO_TEXT_I:
      "Hovedsakelig vil du bli anbefalt porteføljer av fond satt sammen i et fond-i-fond. Dersom du foretrekker enkeltfond handles de direkte i portalen ",

    // Page 1
    PAGE_1_HEADER: "Erfaring",
    PAGE_1_SUBHEADER: "Har du erfaring med fond eller aksjer?",
    PAGE_1_A: "Jeg har ingen erfaring med aksjer eller fond",
    PAGE_1_B: "Jeg har handlet litt aksjer eller fond de siste fem årene",
    PAGE_1_C: "Jeg har handlet fond eller aksjer jevnlig",

    // Page 2
    PAGE_2_HEADER: "Relevant utdanning eller jobb",
    PAGE_2_SUBHEADER: "Har du utdannelse eller jobb som er relevant for fond eller aksjer?",
    PAGE_2_A: "Nei",
    PAGE_2_B: "Ja, jeg har utdannelse eller arbeidserfaring som gjør at jeg forstår fond",

    // Page 3
    PAGE_3_HEADER: "Oppfriskning",
    PAGE_3_SUBHEADER: "Ønsker du friske opp eller lære litt mer om fond og aksjehandel før du går videre?",
    PAGE_3_A: "Nei takk, jeg har god oversikt",
    PAGE_3_B: "Ja, jeg vil lære mer",
    PAGE_3_INFO_TEXT_A_HEADER: "Aksje",
    PAGE_3_INFO_TEXT_A:
      "En eierandel av et selskap. Eier du 1% av aksjene i et selskap, har du rett på 1% av alt selskapet tjener og eier.",
    PAGE_3_INFO_TEXT_B_HEADER: "Obligasjon",
    PAGE_3_INFO_TEXT_B:
      "En andel av et selskaps gjeld. For eksempel låner ikke Equinor bare penger i banken, men også direkte fra investorer som deg. Eier du litt av en obligasjon, skylder selskapet deg penger og betaler renter som på et vanlig banklån.",
    PAGE_3_INFO_TEXT_C_HEADER: "Fond",
    PAGE_3_INFO_TEXT_C:
      "Det finnes flere typer fond; aksjefond, rentefond, eller en kombinasjon av disse. I motsetning til å eie en enkeltaksje består et fond av mange forskjellige aksjer og/eller rentepapirer/obligasjoner, noe som gjør at risiko blir fordelt, og dermed blir lavere. Fondene har strenge regler, for å beskytte deg som investor.",
    PAGE_3_INFO_TEXT_D_HEADER: "Aksjefond",
    PAGE_3_INFO_TEXT_D:
      "Et verdipapirfond der minimum 80 prosent av kapitalen investeres i aksjemarkedet. Aksjefond kan være for hele verden, men også geografisk fokusert (eks Norge), eller til en bransje (eks teknologi) eller et tema (eks miljø).",
    PAGE_3_INFO_TEXT_D_LINK: "For mer informasjon om aksjefond, klikk her.",
    PAGE_3_INFO_TEXT_E_HEADER: "Rentefond",
    PAGE_3_INFO_TEXT_E:
      "Kan deles i to undergrupper; 1) Pengemarkedsfond som har kort bindingstid (under ett år), og som har relativt lav risiko. 2) Obligasjonsfond som har lenger enn ett års bindingstid, og har en høyere risiko. Med en høyere risiko vil vanligvis obligasjonsfond ha en høyere avkastning enn pengemarkedsfond over tid.",
    PAGE_3_INFO_TEXT_E_LINK: "For mer informasjon om rentefond, klikk her.",
    PAGE_3_INFO_TEXT_F_HEADER: "Kombinasjonsfond",
    PAGE_3_INFO_TEXT_F: "Er en blanding av rentefond og aksjefond.",
    PAGE_3_INFO_TEXT_F_LINK: "For mer informasjon om kombinasjonsfond, klikk her.",
    PAGE_3_INFO_TEXT_G_HEADER: "Risiko",
    PAGE_3_INFO_TEXT_G:
      "For å oppnå en høyere avkastning enn vanlig bankinnskudd må en ta risiko. Ved å ta høyere risiko, risikerer en å tape penger. Høyere risiko, og mulig høyere avkastning, kan du som kunde oppnå gjennom å investere i fond, være seg aksjefond, rentefond eller en kombinasjon av disse. Det fins flere typer risiko, men hovedelementet er risikoen for at verdiene svinger på en slik måte at når du trenger pengere fra investeringen så er verdiene lavere enn hva du kjøpte for.",
    PAGE_3_INFO_TEXT_G_2:
      "Risikoen ved investering i fond er redusert ved at et fonds investeringer er spredt på mange ulike enkeltpapir. Et fond kan investere i spesifikke sektorer (sektor risiko), eller i spesifikke geografiske regioner (geografisk risiko), som vil ha større risiko enn et globalt fond.",
    PAGE_3_INFO_TEXT_G_LINK: "For mer informasjon om risiko, klikk her.",
    PAGE_3_INFO_TEXT_H_HEADER: "Fond-i-fond",
    PAGE_3_INFO_TEXT_H:
      "Et fond-i-fond er en investeringsstruktur der et fond investerer i andre fond, i stedet for direkte i aksjer eller obligasjoner. Dette gir investor bredere diversifisering, tilgang til ulike markeder og forvaltningstyper, samtidig som forvaltere håndterer investeringsbeslutningene- både utskiftninger og rebalanseringer. Fond-i-fond kan være et godt valg for de som ønsker en enkel og profesjonell tilnærming til investering. Norne sine fond-i-fond er forvaltet av Norne Securities med Alfred Berg Kapitalforvaltning som administrator.",

    // Page 4
    PAGE_4_HEADER: "Sparemål og planlagt lengde",
    PAGE_4_SUBHEADER:
      "Hva sparer du til og hvor lenge ser du for deg spare? Du kan selvsagt avbryte sparingen og ta ut pengene når du måtte ønske.",
    PAGE_4_INFO_BOX:
      "For å tilpasse risikoen i sparingen din: jo lengre frem i tid du ser for deg å bruke pengene, jo høyere risiko anbefaler vi.",
    PAGE_4_A: "1-3 ÅR",
    PAGE_4_A_1: "Planlagte investeringer eller forbruk (for eksempel reiser, bolig, oppussing)",
    PAGE_4_B: "3-7 ÅR",
    PAGE_4_B_1: "Spare for å spare; Økt økonomisk handlefrihet frem i tid",
    PAGE_4_C: "7-10 ÅR",
    PAGE_4_C_1: "Sparing til pensjon eller spare for å spare",
    PAGE_4_D: "10+ ÅR",
    PAGE_4_D_1: "Sparing til pensjon eller spare for å spare",
    PAGE_4_E: "Gå til IPS-veileder. Bunden pensjonssparing til 62 med skattefradrag",
    PAGE_4_BUTTON_TEXT_ACTIVE: "Lær mer ",
    PAGE_4_INFO_TEXT_A:
      "Aksjer kan svinge mye i verdi, derfor vil sparelengde påvirke hvor stor andel aksjer man bør ha i porteføljen. Søylene under viser historisk årlig avkastning (siste 25 år) for verdensindeksen i norske kroner med 1, 5 og 10 års lengde på sparingen.",
    PAGE_4_INFO_TEXT_B: "1 år",
    PAGE_4_INFO_TEXT_C:
      "Med en engangsplassering i et globalt aksjefond kunne et enkelt år, historisk, med maks uflaks gitt en negativ avkastning på -42%. Med maks flaks på ett år med eietid: 45% pluss.",
    PAGE_4_INFO_TEXT_D: "5 år",
    PAGE_4_INFO_TEXT_E:
      "Dersom du eide minimum 5 år ville avkastningen aldri vært dårligere enn -6% i året, og den beste perioden ville gitt 24% årlig.",
    PAGE_4_INFO_TEXT_F: "10 år",
    PAGE_4_INFO_TEXT_G:
      "På 10 år er dårligste periode -3% per år (-26% over hele perioden), og beste på 13% per år (+239% på pengene).",
    PAGE_4_INFO_TEXT_H:
      "Historisk avkastning gir som kjent ingen garanti for fremtidig avkastning, men gir deg en indikasjon på størrelsen i variasjonene og viktigheten av å være en langsiktig investor.",
    PAGE_4_CHART_HISTORIC: "HISTORISK AVKASTNING",
    PAGE_4_CHART_BEST_PERIOD: "Beste periode",
    PAGE_4_CHART_WORST_PERIOD: "Dårligste periode",
    PAGE_4_CHECKBOX_A: "Sparingen vil utgjøre en mindre del av min totale økonomi",
    PAGE_4_CHECKBOX_B: "Sparingen vil være en stor del av min totale økonomi",
    PAGE_4_DATA_ONE: "1 ÅR",
    PAGE_4_DATA_FIVE: "5 ÅR",
    PAGE_4_DATA_TEN: "10 ÅR",

    // Page 5
    PAGE_5_HEADER: "Risikovilje",
    PAGE_5_INFO_HEADER:
      "Hvor offensiv eller forsiktig du skal være med sparingen avhenger mye av din alder, hvor godt din økonomi tåler svingninger i oppsparte verdier og hvor lenge du planlegger å spare. Tommelfingerregelen er at du jo lenger du skal spare jo mer offensiv kan du være.",
    PAGE_5_HEADER_LINK: "Les og lær mer om sparing og fond her (ekstern lenke)",
    PAGE_5_SUBHEADER: "Hva kjenner du deg best igjen i her?",
    PAGE_5_A: "VELDIG FORSIKTIG:",
    PAGE_5_A_1: "Jeg ønsker ikke å ta risiko på sparepengene mine og vil ha de på en bankkonto",
    PAGE_5_B: "FORSIKTIG: BEVARING AV FORMUE",
    PAGE_5_B_1:
      "Noe svingninger tåler jeg for å få litt høyere avkastning på pengene over tid, og slik beskytte verdiene mot inflasjon",
    PAGE_5_C: "BALANSERT: ØKE FORMUEN GJENNOM AVKASTNING",
    PAGE_5_C_1: "Min økonomi er robust og jeg tåler svingninger godt for å oppnå høyere avkastning",
    PAGE_5_D: "OFFENSIV: HØY AVKASTNING PÅ PENGENE FOR Å TJENE MEST MULIG",
    PAGE_5_D_1: "Være med på verdiskapningen i samfunnet. Jeg både tåler og er forberedt på store svingninger.",
    PAGE_5_TOGGLE: "Lær mer om svingninger",
    PAGE_5_INFO_TEXT_A:
      "Å ha alle pengene plassert i aksjemarkedet har historisk gitt høyest avkastning. Men også størst svigninger og mest ubehag. Under er utviklingen for Oslo Børs siste 25 år, sammen med pengemarkedsrenten (Nibor), og forskjellige kombinasjoner av de to.",
    PAGE_5_INFO_TEXT_B:
      "En ren renteportefølje skal gi litt mer avkastningen enn banksparing, men med noe variasjoner. En bankkonto går aldri i minus, men rentefond har i perioder negativ avkastning. Kombinasjoner av renter og aksjer gjør da at man kan få en sparing som svinger akkurat så mye som man synes er behagelig. Grafene over viser utviklingen med alt plassert i aksjer, alt i lavrisiko-renter og tre forskjellige kombinasjoner av de to. Men grunnregelen er at jo mer svingninger man får, jo mer bør man forvente i avkastning.",
    PAGE_5_INFO_TEXT_C:
      "Grafen som illustrerer renter over er pengemarkedsrenten, som er tilnærmet risikofri. Rentefondene du vil få anbefalt svinger mer enn dette.",
    PAGE_5_GRAPH_STOCKS: "100% Aksjer",
    PAGE_5_GRAPH_BALANCED: "50/50",
    PAGE_5_GRAPH_INTEREST: "100% Renter",

    SHORT_HORIZON_USER_MESSAGE:
      "Kort horisont kombinerer dårlig med ønsket om høy avkastning: man risikerer å måtte selge på ugunstige tidspunkt. Les gjerne mer om avkastning og risiko ",
    SHORT_HORIZON_GO_BACK: "Gå tilbake",
    SHORT_HORIZON_CONTINUE: "Fortsett",

    // Page 6
    PAGE_6_HEADER: "Is i magen?",
    PAGE_6_INFO_HEADER:
      "Din spareplan er snart klar, men for å gi deg de beste rådene om sparing trenger vi å vite litt mer om deg.",
    PAGE_6_SUBHEADER_2: "Om det kom et kraftig fall på børsen, ville du",
    PAGE_6_A: "Solgt unna for å minimere tap",
    PAGE_6_B: "Forholdt deg rolig, gjort ingenting",
    PAGE_6_C: "Benyttet sjansen til å kjøpe litt ekstra",
    PAGE_6_BUTTON_TEXT: "Mer om markedsfall",
    PAGE_6_INFO_TEXT:
      "Med sparing på Oslo Børs ville man de siste 40 årene opplevd flere store fall. Mange selskaper har gått konkurs, men med sparing i fond eier du litt av mange selskaper og slik en andel av samfunnets verdiskapning. Dermed har historien vist at fall har bydd på muligheter. Markedsfall har også en tendens til å være raskere enn oppgangsperiodene. I snitt har Oslo Børs gitt over 12 prosent årlig avkastning siden begynnelsen av 80-tallet.",

    // Page 7
    PAGE_7_HEADER: "Bærekraft",
    PAGE_7_INFO_HEADER:
      "Vi har fokus på bærekraft og alle forvalterne som velges har klare regler for bærekraft i forvaltningen. Samtidig er det forskjell på fond og hvordan de investerer.",
    PAGE_7_SUBHEADER:
      "Ønsker du økt bærekraftfokus i din portefølje, og er du komfortabel med at det kan gi både negativ og positiv effekt på avkastningen din?",
    PAGE_7_LINK_TEXT: "Les mer på vår bærekraftsside",

    PAGE_7_INFO_TEXT_1_HEADER: "Bærekraft",
    PAGE_7_INFO_TEXT_1_A:
      "Bærekraftsfaktorer er miljøspørsmål, sosiale spørsmål og personalspørsmål samt spørsmål knyttet til respekt for menneskerettighetene og bekjempelse av korrupsjon. Økt bærekraftfokus i din portefølje kan gi både negativ og positiv effekt på avkastningen.",
    PAGE_7_INFO_TEXT_1_B: "Eksempel på positive effekter:",
    PAGE_7_INFO_TEXT_1_C: "- Lavere risiko for miljø- og sosiale utfordringer, og slik fall i aksjekurser",
    PAGE_7_INFO_TEXT_1_D: "- Vekstmuligheter",
    PAGE_7_INFO_TEXT_1_E: "Eksempel på negative effekter:",
    PAGE_7_INFO_TEXT_1_F: "- Begrenser utvalget av investerbare selskaper og dermed en mindre diversifisert portefølje",
    PAGE_7_INFO_TEXT_1_G: "- Høyere kostnader ved å investere bærekraftig",
    PAGE_7_OPTIONS_1_A: "Bærekraft er viktig for min portefølje.",
    PAGE_7_OPTIONS_1_B: "Jeg forholder meg nøytral til bærekraft.",

    PAGE_7_INFO_TEXT_2_HEADER: "Sustainable Finance Disclosure Regulation (SFDR)",
    PAGE_7_INFO_TEXT_2_A: "Klassifisering av alle fond i EU for å beskrive bærekraftshensyn i investeringsbeslutninger.",
    PAGE_7_INFO_TEXT_2_B: "Artikkel 9-fond",
    PAGE_7_INFO_TEXT_2_C: "Bærekraftige investeringer som overordnet mål. Gjerne omtalt som mørkegrønne fond.",
    PAGE_7_INFO_TEXT_2_D: "Artikkel 8-fond",
    PAGE_7_INFO_TEXT_2_E: "Fond som fremmer miljømessige eller sosiale egenskaper. Gjerne omtalt som lysegrønne fond.",
    PAGE_7_INFO_TEXT_2_F: "Artikkel 6-fond",
    PAGE_7_INFO_TEXT_2_G:
      "Hensyntar bærekraftsrisiko, men fremmer ikke miljømessige eller sosiale egenskaper. Det finansielle produktets underliggende investeringer tar ikke hensyn til EU-kriteriene for miljømessig bærekraftige økonomiske aktiviteter.",
    PAGE_7_OPTIONS_2_A: "SFDR er viktig for min portefølje.",
    PAGE_7_OPTIONS_2_B: "Jeg forholder meg nøytral til SFDR.",

    PAGE_7_INFO_TEXT_3_HEADER: "Principal Adverse Impacts (PAI)",
    PAGE_7_INFO_TEXT_3_A:
      "Investeringenes negative påvirkning på bærekraftsfaktorer, herunder klimagassutslipp, naturmangfold, utslipp, avfall, sosiale forhold (barnearbeid, sosial dumping, jobbsikkerhet, HMS, diskriminering), kontroversielle våpen, hensyn til urbefolkning, bruk av vann og landområder.",
    PAGE_7_INFO_TEXT_3_B:
      "Regelverket for PAI er under utvikling og det foreligger per i dag ikke fullgode data for alle de negative konsekvensene for bærekraftsfaktorer (PAI). Det lar seg derfor ikke gjøre å fullt ut tilpasse anbefalinger til investors bærekraftspreferanser før kvaliteten på klassifiseringssystemet og datatilgangen blir mer presis. Norne vil likevel ta hensyn til kundens preferanser så langt det til enhver tid er mulig og for øvrig i samsvar med vår bærekraftspolicy.",

    PAGE_7_OPTIONS_3_A: "Principal Adverse Impacts er viktig for min portefølje",
    PAGE_7_OPTIONS_3_B: "Jeg forholder meg nøytral til Principal Adverse Impacts.",

    PAGE_7_INFO_TEXT_4_HEADER: "EU-taksonomi",
    PAGE_7_INFO_TEXT_4_A:
      "Taksonomien er et klassifiseringssystem som skal gjøre det mulig å si hvilke investeringer som er bærekraftige, ved at foretak må rapportere hvordan de presterer på seks ulike miljømål:",
    PAGE_7_INFO_TEXT_4_B: "1. Redusere og forebygge klimautslipp",
    PAGE_7_INFO_TEXT_4_C: "2. Klimatilpasning",
    PAGE_7_INFO_TEXT_4_D: "3. Bærekraftig bruk og beskyttelse av vann- og marine ressurser",
    PAGE_7_INFO_TEXT_4_E: "4. Overgang til en sirkulær økonomi, avfallsforebygging og gjenvinning",
    PAGE_7_INFO_TEXT_4_F: "5. Forebygging og kontroll av forurensning",
    PAGE_7_INFO_TEXT_4_G: "6. Vern og restaurering av naturmangfold og økosystemer",
    PAGE_7_INFO_TEXT_4_H:
      "For å være bærekraftig må aktiviteten både bidra til minst ett av de seks målene og ikke forårsake betydelig på noen av de andre målene.",
    PAGE_7_INFO_TEXT_4_I:
      "Fond skal rapportere hvor stor andel av fondets investeringer som skal være i økonomiske aktiviteter som er miljømessig bærekraftige etter taksonomien. Taksonomien er under utvikling og kriteriene for alle miljømål er ennå ikke klare. Det er heller ikke etablert en beregningsmodell for andelen av fondets investeringer som er i samsvar med taksonomien. Selskapene som fondet investerer i, rapporterer i begrenset grad i hvilken grad deres aktiviteter er forenlig med EU-taksonomien. Vi vurderer derfor at det på nåværende tidspunkt ikke er mulig å gi pålitelig informasjon om hvor stor andel av porteføljens investeringer som skal være i økonomiske aktiviteter som er miljømessig bærekraftige etter taksonomien.",
    PAGE_7_OPTIONS_4_A: "EU-taksonomi er viktig for min portefølje.",
    PAGE_7_OPTIONS_4_B: "Jeg forholder meg nøytral til EU-taksonomi.",

    // Page 8
    PAGE_8_HEADER: "Fri likviditet",
    PAGE_8_SUBHEADER:
      "Hvor mye har du igjen, hver måned, av lønn og andre inntekter etter at alle utgifter er betalt og annen sparing er trukket?",
    PAGE_8_A: "Jeg har mer utgifter enn inntekter",
    PAGE_8_B: "100 - 2 000,-",
    PAGE_8_C: "2 000 - 10 000,-",
    PAGE_8_D: "Over 10 000,-",

    // Page 9
    PAGE_9_HEADER: "Bankinnskudd",
    PAGE_9_INFO_BOX: "Total fri finansiell formue er førende for hvor mye svingninger man som sparer bør påta seg.",
    PAGE_9_SUBHEADER:
      "Hvor mye penger har du på spare- og høyrentekonto i dag? Oppgi innskudd i alle banker, men hold bundne innskudd som BSU og pensjon utenfor.",
    PAGE_9_A: "0 - 150 000,-",
    PAGE_9_B: "150 000 - 500 000,-",
    PAGE_9_C: "Over 500 000",

    // Page 10
    PAGE_10_HEADER: "Fond og aksjer",
    PAGE_10_INFO_BOX: "Total fri finansiell formue er førende for hvor mye svingninger man som sparer bør påta seg.",
    PAGE_10_SUBHEADER:
      "Hva er verdien på fond og aksjer du allerede eier? Hold bunden pensjons-sparing i fond og aksjer utenfor.",
    PAGE_10_SUB_QUESTION: "Hvorfor spør vi om dette?",
    PAGE_10_INFO: "Total fri finansiell formue er førende for hvor mye svingninger man som sparer bør påta seg.",
    PAGE_10_CLOSE: "Lukk",
    PAGE_10_A: "0 - 150 000,-",
    PAGE_10_B: "150 000 - 500 000,-",
    PAGE_10_C: "Over 500 000",

    // Page 11
    PAGE_11_HEADER: "Gjeld og verdier",
    PAGE_11_SUBHEADER:
      "Jeg har mer verdier enn gjeld. Verdier er hus, hytte, bil og båt, samt bankinnskudd og fond/aksjer. Studiegjeld kan utelates fra regnestykket.",
    PAGE_11_A: "Nei",
    PAGE_11_B: "Ja",
    PAGE_11_INFO: "Fondssparing er ikke anbefalt dersom man har dyr gjeld.",
    PAGE_11_CONFIRM:
      "Jeg bekrefter at jeg ikke har forbrukslån og/eller rentebærende kredittkortgjeld. Eventuelt: de vil bli nedbetalt før oppstart fondssparing.",
    PAGE_11_REDIRECT: "Du vil nå bli videresendt til innlogging før du kan fullføre spareroboten.",
    PAGE_11_REDIRECT_SAFARI:
      "Du vil nå bli videresendt til innlogging før du kan fullføre spareroboten. De siste stegene av spareroboten gjennomføres via nettsidene til Norne Securities.",

    // Page 12
    PAGE_12_HEADER: "Risikonivå",
    PAGE_12_SUBHEADER: "Analysen viser at sparing med en ",
    PAGE_12_SUBHEADER_B: " risikoprofil passer for deg",
    PAGE_12_ADJUST: "Juster ditt risikonivå",
    PAGE_12_CAREFUL: "Forsiktig",
    PAGE_12_CAREFUL_TEXT: "forsiktig",
    PAGE_12_BALANCED: "Balansert",
    PAGE_12_BALANCED_TEXT: "balansert",
    PAGE_12_OFFENSIVE: "Offensiv",
    PAGE_12_OFFENSIVE_TEXT: "offensiv",
    PAGE_12_EXTRA_OFFENSIVE_TEXT: "ekstra offensiv",
    PAGE_12_API_CAREFUL: "Careful",
    PAGE_12_API_BALANCED: "Balanced",
    PAGE_12_API_OFFENSIVE: "Offensive",
    PAGE_12_API_EXTRA_OFFENSIVE: "ExtraOffensive",

    // Page 13
    PAGE_13_HEADER: "Spareavtaledetaljer",
    PAGE_13_SUBHEADER:
      "Hvor mye ønsker du å spare? Husk at beløpet du velger ikke er bindende, og kan når som helst endres etter eget ønske eller behov. Månedlig sparebeløp bør være andel av månedlig overskudd på brukskonto etter alle kostnader og eventuell annen sparing. Dersom du er under 33 år, ikke eier egen bolig og betaler skatt, bør BSU fylles opp før fondssparing",
    PAGE_13_SUBHEADER_INFO_QUESTION: "Hva vil oppstartsbeløp si?",
    PAGE_13_SUBHEADER_INFO:
      "Oppstartsbeløp gjøres over valgt periode som et fast månedlig trekk. Første trekk vil være i morgen. Dersom du er kunde hos Sparebank 68gr Nord, Aasen Sparebank, Selbu Sparebank, Nidaros Sparebank, Stadsbygd Sparebank, Ørland Sparebank, Tolga-Os Sparebank, Sparebanken DIN, Drangedal Sparebank eller A & S Sparebank gjøres dette som et engangstrekk.",
    PAGE_13_START_AMOUNT: "OPPSTARTSBELØP",
    PAGE_13_START_AMOUNT_SPREAD: "OPPSTARTSBELØPET SKAL FORDELES OVER",
    PAGE_13_MONTHLY_SAVING: "MÅNEDLIG SPARING",
    PAGE_13_MONTHLY_AVERAGE: "Den gjennomsnittlige spareavtalen i fond er på kr. 1 000,-",
    PAGE_13_VALUE_OPTIMISTIC: "Optimistisk verdi",
    PAGE_13_VALUE_EXPECTED: "Forventet verdi",
    PAGE_13_VALUE_CONSERVATIVE: "Konservativ verdi",
    PAGE_13_VALUE_AMOUNT: "Innskutt beløp",
    PAGE_13_A: "1 MND",
    PAGE_13_B: "3 MND",
    PAGE_13_C: "6 MND",
    PAGE_13_D: "12 MND",
    PAGE_13_E: "Egendefinert",

    PAGE_13_REQUIREMENTS_HEADER: "Forutsetninger",
    PAGE_13_TEXT_A: "Avkastningsforutsetningene som er benyttet er:",
    PAGE_13_TEXT_B: "Forventet verdi: aksjer 6,5% årlig, renter: 3,5% årlig.",
    PAGE_13_TEXT_C: "Optimistisk verdi: aksjer 8,5% årlig, renter: 4,5% årlig.",
    PAGE_13_TEXT_D: "Konservativ verdi: aksjer 3,75% årlig, renter: 2,5% årlig.",
    PAGE_13_TEXT_E:
      "Prognosene for fremtidig avkastning er basert på historisk avkastning. Vi gjør oppmerksom på at historisk avkastning ikke er noen garanti for fremtidig avkastning.",
    PAGE_13_TEXT_F:
      "Fremtidig avkastning vil blant annet avhenge av markedsutviklingen, forvalters dyktighet, fondets risiko samt kostnader ved forvaltning. Avkastningen kan bli negativ som følge av kurstap. Avkastningen er fratrukket årlig forvaltningshonorar på 0,5% for aksjer og 0,3% for renter.",
    PAGE_13_TEXT_G: "Skatt og inflasjon er ikke hensyntatt i beregningene.",
    PAGE_13_TEXT_H: "Plattformhonorar er ikke hensyntatt i beregningene.",

    PAGE_13_NOT_ENOUGH_LIQUIDITY_TEXT: "Beløpet er høyere enn oppgitt fri likviditet. Vil du gå tilbake og endre tidligere svar?",
    PAGE_13_NOT_ENOUGH_SAVINGS_TEXT: "Beløpet er høyere enn oppgitt bankinnskudd. Vil du gå tilbake og endre tidligere svar?",
    PAGE_13_GO_BACK_TO_LIQUIDITY: "Gå tilbake til fri likviditet",
    PAGE_13_CONTINUE: "Fortsett",
    PAGE_13_GO_BACK_TO_SAVINGS: "Gå tilbake til bankinnskudd",
    PAGE_13_ABOVE_MAX_AMOUNT_TEXT:
      "Begrensning på 1 mnok og ta kontakt for utvidet gjennomgang, eller gjør tegning direkte i portalen",
    PAGE_13_ABOVE_MAX_AMOUNT_BUTTON_TEXT: "Lukk",

    PAGE_13_NUM_YEARS: "Antall spareår som vises i graf:",
    PAGE_13_SAVING_AMOUNT: "Forventet sparebeløp per",
    PAGE_13_YEAR: "År",
    PAGE_13_SAVINGS_RESULT: "Slik ser det ut med kr. ",
    PAGE_13_SAVINGS_RESULT_B: ",- i oppstartsbeløp og kr. ",
    PAGE_13_SAVINGS_RESULT_C: ",- i månedlig sparing. Skatt og inflasjon er ikke hensyntatt i simuleringen.",
    PAGE_13_SAVINGS_DISCLAIMER:
      "Fremstillingen er stilisert og tar ikke opp i seg svingningene i verdiene som vil komme. Spareverdien vil ikke utvikle seg stabilt opp som her.",

    PAGE_13_TEST_WITH_RISK_PROFILE_LABEL: "Prøv med ulike risikoprofiler (dette endrer ikke spareforslaget)",
    PAGE_13_TEST_WITH_RISK_CAREFUL_LABEL: "Forsiktig",
    PAGE_13_TEST_WITH_RISK_BALANCED_LABEL: "Balansert",
    PAGE_13_TEST_WITH_RISK_OFFENSIVE_LABEL: "Offensiv",
    PAGE_13_TEST_WITH_RISK_EXTRA_OFFENSIVE_LABEL: "Ekstra offensiv",

    // Page 14
    PAGE_14_HEADER: "Spareforslag",
    PAGE_14_KIID_HEADER: "KIID",
    PAGE_14_INFO_TEXT:
      "Deler av eller alle plasseringer gjøres gjennom fond i fond (Norne Aksje, Kombi eller Rente) for slik å gjøre porteføljen mest mulig vedlikeholdsfri. Oppfølging, rapportering og skatt blir enklere og optimaliseres. Under er alle underliggende fond vist.",
    PAGE_14_PORTFOLIO: "Anbefalt portefølje",
    PAGE_14_COLUMN_HEADER: "Type",
    PAGE_14_COLUMN_HEADER_A: "Fond",
    PAGE_14_COLUMN_HEADER_B: "Bærekraftsrating",
    PAGE_14_COLUMN_HEADER_C: "SFDR",
    PAGE_14_COLUMN_HEADER_D: "Fordeling",
    PAGE_14_COLUMN_HEADER_E: "KIID",

    // Page 15
    PAGE_15_HEADER: "Forventet resultat",
    PAGE_15_ANNUAL: "Årlige kostnader",
    PAGE_15_DECADE: "Kostnader over 10 år",
    PAGE_15_COST: "Kostnad",
    PAGE_15_PERCENTAGE: "Prosent",
    PAGE_15_PRICE: "Pris",
    PAGE_15_DEPOT: "Depotkostnader",
    PAGE_15_COMMISION: "Forvaltningshonorar",
    PAGE_15_VALUE_TOTAL: "Porteføljeverdi",
    PAGE_15_COMMISIONER: "Til forvalter",
    PAGE_15_TRANSACTION_COST: "Transaksjonskostnader",
    PAGE_15_COST_BANK: "Til bank/Norne",
    PAGE_15_TOTAL_COST: "Total kostnad",
    PAGE_15_TOTAL: "Totalt",
    PAGE_15_EXPECTED_VALUE: "Forventet verdi etter kostnader",
    PAGE_15_EXPECTED_PROFIT: "Forventet avkastning er",
    PAGE_15_PERCENTAGE_SIGN: "%",
    PAGE_15_BEFORE_COST: "før kostnad, ",
    PAGE_15_AFTER_COST: "etter kostnad",
    PAGE_15_AND: "og ",
    PAGE_15_FINANCE_PORTAL_PRE_TEXT: "Du kan sammenligne priser på ",
    PAGE_15_FINANCE_PORTAL_LINK_TEXT: "finansportalen.no",

    // Page 16
    PAGE_16_HEADER: "Start sparing",
    PAGE_16_NAME: "NAVN PÅ SPAREAVTALEN",
    PAGE_16_NAME_DEFAULT: "Min spareavtale",
    PAGE_16_DATE: "Velg trekkdato",
    PAGE_16_ACCOUNT: "Velg hvilken fondskonto fondsandelene skal gå til",
    PAGE_16_ACCOUNT_DEFAULT: "VELG KONTO",
    PAGE_16_DEPOSIT: "Velg hvilken bankkonto pengene skal trekkes fra",
    PAGE_16_DEPOSIT_DEFAULT: "BANKKONTOER",
    PAGE_16_DEPOSIT_WARNING: "Klarer ikke å hente ut bankkontoer fra Evry",
    PAGE_16_CREATE_ASK: "Opprett ny ASK",
    PAGE_16_A: "Sparerobot er gjennomført på egenhånd",
    PAGE_16_B: "Sparerobot er gjennomført sammen med rådgiver",
    PAGE_16_DATE_A: "1.",
    PAGE_16_DATE_B: "10.",
    PAGE_16_DATE_C: "15.",
    PAGE_16_DATE_D: "25.",
    PAGE_16_ADVISOR: "Hvilken rådgiver gjennomførte spareroboten med deg?",
    PAGE_16_INFO_BOX:
      "Dette spareforslaget krever at man plasserer fondene på fondskonto. Rentefond og kombinasjonsfond med mindre enn 80% aksjeandel kan ikke plasseres på Aksjesparekonto. Fondskonto har ikke utsatt beskatning ved realisasjoner.",
    PAGE_16_FOOTER_HEADER: "Du er nesten ferdig. Klikk start sparing for å opprette din nye spareavtale.",
    PAGE_16_FOOTER_TEXT: "Husk at spareavtalen kan stoppes når som helst. Engangstegninger kan stoppes frem til midnatt i dag.",

    // Page 17
    PAGE_17_HEADER: "",
    PAGE_17_SUBHEADER: "Gratulerer med et klokt valg. Nå er det bare å lene seg tilbake, slappe av, og la pengene jobbe for deg.",
    PAGE_17_INGRESS:
      "Oppsummeringen er også sendt til din postkasse i spareportalen. Dersom du ikke ønsker elektronisk kommunikasjon, ta kontakt med kundeservice.",
    PAGE_17_START_HEADER: "Oppstartsavtale",
    PAGE_17_START_HEADER_ONE_TIME: "Engangsinnskudd",
    PAGE_17_PLACEMENT: "Spares til fondskonto: ",
    PAGE_17_WITHDRAW: "Trekkonto: ",
    PAGE_17_FUND: "Fond",
    PAGE_17_AMOUNT: "Månedlig sparebeløp",
    PAGE_17_AMOUNT_STARTUP: "Beløp:",
    PAGE_17_DATE_START: "Startdato:",
    PAGE_17_DATE_STOP: "Sluttdato:",
    PAGE_17_NO_DATE: "Ingen sluttdato",
    PAGE_17_SUITABILITY_HEADER: "Egnethetserklæring",
    PAGE_17_BUTTON_PRIMARY: "Skriv ut oppsummering",
    PAGE_17_BUTTON_SECONDARY: "Tilbake til min oversikt",

    PAGE_17_AVTALE_GIRO_ERROR:
      "AvtaleGiro ble ikke opprettet, du må opprette avtaleGiro manuelt i din nettbank til kontonummer $KONTONUMMER med KID $KID",

    // Create ASK
    ASK_HEADING: "Opprett Aksjesparekonto (ASK)",
    ASK_CORPORATE_ERROR_TEXT: "ASK er ikke tilgjengelig for bedrifter",
    ASK_BANK_LABEL: "Velg bank",
    ASK_BANK_PLACEHOLDER: "Velg bank",
    ASK_CREATE_MISSING_BANK_ERROR: "Du må velge bank",

    ASK_TERMS_LINK: "Les vilkår for opprettelse av aksjesparekonto",
    ASK_TERMS_HEADER: "Avtale om Aksjesparekonto",
    ASK_TERMS_1:
      "Mellom Norne Securities AS (Foretaket) og Kunden er det inngått avtale om Aksjesparekonto. Avtalen kommer i tillegg til eksisterende Kundeavtale med ",
    ASK_TERMS_1_A: "(Banken) og Kundeavtale inngått mellom Foretaket og Kunden.",
    ASK_TERMS_2:
      "Ved signering av Kundeavtalen har Kunden også akseptert Foretakets Forretningsvilkår. Banken har innsynsrett i kundens Aksjesparekonto.",
    ASK_TERMS_3: "Kunden bekrefter at Foretaket har gitt informasjon om: ",
    ASK_TERMS_4: "- hvilke midler som kan inngå på kontoen",
    ASK_TERMS_5: "- relevante skatteregler",
    ASK_TERMS_6: "- kostnader knyttet til kontoen",
    ASK_TERMS_7: "- at det ikke løper renter på innestående kontanter",
    ASK_TERMS_8: "- forholdet til innskuddsgarantiordningen",

    ASK_TERMS_9: "1. Hva avtalen gjelder",
    ASK_TERMS_10:
      "Avtalen gjelder opprettelse av Aksjesparekonto, føring av kontoen og oppbevaring av finansielle instrumenter og kontanter på kontoen. Innskudd på kontoen kan benyttes til å investere i børsnoterte aksjer, børsnoterte egenkapitalbevis og andeler i aksjefond i Norge («Investeringsuniverset»). Aksjesparekontoen kan dermed ikke benyttes for handel i «exchange traded notes» (ETN), derivater, obligasjoner eller rentefond. Kombinasjonsfond må ha en aksjeandel på mer enn 80% for å omfattes av ordningen. Aksjeandelen beregnes ved inntektsårets begynnelse. Gevinster ved realisasjon av finansielle instrumenter i Investeringsuniverset skattlegges ikke i forbindelse med realisasjonen, men først når midlene tas ut av kontoen. Mottatt utbytte blir derimot skattlagt løpende. Ved overføring til Aksjesparekontoen vil inngangsverdien videreføres med kontinuitet. Ved uttak fra kontoen anses Kundens innskudd som det som tas først ut. Uttak ut over det som er skutt inn på kontoen, skattlegges som aksjeinntekt hos Kunden.",

    ASK_TERMS_11: "2. Opprettelse av Aksjesparekonto",
    ASK_TERMS_12: "Foretaket har opprettet en Aksjesparekonto for Kunden på de vilkår som fremgår av Avtalen.",

    ASK_TERMS_13: "3. Føring og vedlikehold av Aksjesparekontoen",
    ASK_TERMS_14: "Kontoen angir de opplysninger som til enhver tid kreves etter regelverket for Aksjesparekonto, for tiden: ",
    ASK_TERMS_14_B: "- innskudd på kontoen",
    ASK_TERMS_14_C: "- oversikt over finansielle instrumenter ",
    ASK_TERMS_14_D: "- kontantbeholdning",
    ASK_TERMS_14_E: "- ubenyttet skjermingsfradrag fra tidligere år",
    ASK_TERMS_14_F: "- skjermingsgrunnlag",
    ASK_TERMS_14_G: "- beregnet skjermingsfradrag for skattleggingsperioden",
    ASK_TERMS_14_H: "- kontoens markedsverdi ved årsskiftet",
    ASK_TERMS_14_I: "- mottatt utbytte",
    ASK_TERMS_14_J: "Foretaket sørger for oppdatering av opplysningene ved endringer.",

    ASK_TERMS_15: "4. Oppbevaring av kontante midler",
    ASK_TERMS_16: "Kundens kontantbeholdning oppbevares på Foretakets klientkonto.",

    ASK_TERMS_17: "5. Oppbevaring av finansielle instrumenter",
    ASK_TERMS_18:
      "Finansielle instrumenter som er registrert i Verdipapirsentralen (VPS) vil bli oppbevart på Kundens VPS-konto merket ASK. Ved inngåelsen av denne avtalen har Kunden gitt Foretaket handelsfullmakt på denne kunden, og Fortaket er Kundens kontofører For finansielle instrumenter som ikke er registrert i VPS, for eksempel aksjefondsandeler, har Foretaket opprettet en nominee-konto i Foretakets navn hos fondsforvaltningsselskapet og Kundens aksjefondsandeler er overført fra Kundens konto i andelseierregisteret til Foretakets nominee-konto.",

    ASK_TERMS_19: "6. Skatterapportering",
    ASK_TERMS_20:
      "Foretaket er ansvarlig for skatterapportering av aksjesparerkontoen. Foretaket vil rapportere følgende opplysninger til Skatteetaten:",
    ASK_TERMS_20_B: "- Innskuddene (inngangsverdien) ",
    ASK_TERMS_20_C: "- Ubenyttet skjermingsfradrag fra tidligere år ",
    ASK_TERMS_20_D: "- Skjermingsgrunnlag ",
    ASK_TERMS_20_E: "- Mottatt utbytte ",
    ASK_TERMS_20_F: "- Skattepliktig utbytte",
    ASK_TERMS_20_G: "- Kontantbeholdning",
    ASK_TERMS_20_H: "- Uttak fra kontoen i løpet av skattleggingsperioden",
    ASK_TERMS_20_I: "- Beregnet skjermingsfradrag i skattleggingsperioden",
    ASK_TERMS_20_J: "- Skattepliktig gevinst/tap ved uttak på kontoen i løpet av skattleggingsperioden ",
    ASK_TERMS_20_K: "- Kontoens markedsverdi (formuesverdi) ved årsskiftet",
    ASK_TERMS_20_L:
      "Ved inngåelsen av avtalen om Aksjesparekonto gir Kunden Foretaket fullmakt til å innhente opplysninger som trengs for korrekt skatterapportering hos skattemyndigheter, forvaltningsselskaper for aksjefond og eventuelle relevante organer eller foretak.",

    ASK_TERMS_21: "7. Flytting av Aksjesparekonto",
    ASK_TERMS_22:
      "Kunden kan flytte sin aksjesparerkonto helt eller delvis til annen Tilbyder etter begjæring fra Kunden. Foretaket er da forpliktet til å overføre all relevant informasjon av skattemessig betydning til Tilbyder. Ved Kundens anmodning om flytting, anses Kunden i å ha samtykket i at all relevant informasjon overføres til Tilbyder. Foretaket vil ved flyttebegjæring overføre verdipapirer, kontantbeholdning og nødvendige skatteopplysninger innen 10 dager etter at flyttebegjæring er mottatt. Dersom oppgjørsfristen for salg av finansielle instrumenter er lenger enn 10 dager, overførsel skje innen 5 dager etter oppgjørsfristen for salget.",

    ASK_TERMS_23: "8. Opphør av Aksjesparekonto",
    ASK_TERMS_24:
      "Kunden kan begjære Aksjesparekontoen for opphørt. Foretaket vil ved begjæring av Kunden om opphør - etter kundens instruks - realisere finansielle instrumenter på Aksjesparekontoen eller overføre de finansielle instrumenter til relevante Kontoer som kunden oppgir.",

    ASK_INFO_LINK: "Informasjon om aksjesparekonto",
    ASK_INFO_HEADER: "Relevante skatteregler",
    ASK_INFO_1:
      "Skattefordelene ved aksjesparekonto innebærer at du kan bytte aksjefond og aksjer uten at det utløser skatt umiddelbart. Skatten utløses først når gevinsten tas ut av Aksjesparekontoen. Du kan derfor ta ut opp til opprinnelig investert beløp uten å realisere skatt på gevinsten.",
    ASK_INFO_2:
      "Dersom du realiserer tap vil du ikke få fradrag for tapet uten å avslutte Aksjesparekontoen. Du oppnår fremdeles skjermingsfradrag på midler på aksjesparekontoen.",
    ASK_INFO_3: "Hvilke midler kan inngå på kontoen?",
    ASK_INFO_4:
      "Du kan eie norske børsnoterte aksjer og egenkapitalbevis, og norske aksjefond med en aksjeandel på over 80%. Du kan også oppbevare kontanter på aksjesparekonto, men husk at det ikke betales renter på kontanter på aksjesparekonto.",

    ASK_INFO_5: "Renter",
    ASK_INFO_6:
      "Etter gjeldende regler er det ikke tillatt å utbetale renter på kontantinnskudd på aksjesparekonto. Eventuelle kontanter på aksjesparekonto vil derfor ikke være rentebærende.",

    ASK_INFO_7: "Kostnader",
    ASK_INFO_8: "Kostnader forbundet med aksjesparekonto fremgår av vår ",
    ASK_INFO_8_B: "prisliste",

    ASK_INFO_9: "Innskuddsgarantiordningen",
    ASK_INFO_10:
      "Innskuddsgarantiordningen gjelder kontantinnskudd i bank og skal beskytte kunden dersom banken settes under offentlig administrasjon (går konkurs). Aksjesparekonto er ikke en bank-konto og er derfor ikke tilknyttet innskuddsgarantiordningen.",

    ASK_INFO_11:
      "Dine finansielle instrumenter på aksjesparekontoen kan bestå av aksjer, egenkapitalbevis og verdipapirfondsandeler. Aksjer og egenkapitalbevis vil stå oppført på din VPS-konto. Verdipapirfondsandeler registreres på Nornes nominee-konto. Kunden vil til enhver tid ha fullt innsyn i sin VPS-konto og beholdning av andeler i verdipapirfond registrert hos Norne. Dine finansielle instrumenter vil holdes utenfor en eventuell konkurs i Norne og du vil være sikret tilgang til disse om så skulle skje. De finansielle instrumentene på nominee-konto er dekket av ",

    ASK_INFO_11_B: "Verdipapirforetakenes sikringsfond",
    ASK_INFO_11_C: ", som dekker opp til NOK 200.000,- per kunde.",

    ASK_INFO_12:
      "Kontantinnskudd på aksjesparekonto vil være tilknyttet Norne sin klientkonto. Norne plikter til enhver tid å holde kundens midler atskilt fra Nornes egne midler og andre kunders midler ved separat registrering. Klientmidler vil således holdes utenfor boet i forbindelse med en eventuell konkurs i Norne. ",

    ASK_INFO_13: "Bankenes sikringsfond ",
    ASK_INFO_14:
      " dekker bankinnskudd opp til NOK 2 millioner per kunde dersom banken settes under offentlig administrasjon. For innskudd på klientkonto i Nornes navn anses Norne som kunde, slik at grensen på NOK 2 millioner gjelder for samlede innskudd på klientkontoen i banken.",

    ASK_CONFIRM: "Jeg godtar vilkårene",
    ASK_BUTTON: "Opprett aksjesparekonto",
    ASK_RECEIPT: "Din Aksjesparekonto er nå opprettet.",
    ASK_ERROR_GENERIC: "Noe gikk galt ved opprettelse av din Aksjesparekonto.",
    ASK_ERROR_MAX_ACCOUNTS: "Du kan ikke opprette flere kontoer.",
    ASK_DEFAULT_NAME: "Aksjesparekonto",
  },
  secondary: {
    NORNE: "Norne",
    TEXT_LANGUAGE_A: "Bokmål",
    TEXT_LANGUAGE_B: "Nynorsk",
    TEXT_YES: "JA",
    TEXT_NO: "NEI",
    TEXT_CLOSE: "Lukk",
    TEXT_QUESTION: "Kvifor spør vi om dette?",

    BUTTON_NEXT: "Neste",
    BUTTON_BACK: "Tilbake",
    BUTTON_START: "Start",
    BUTTON_START_SAVING: "Start spareroboten",
    BUTTON_COMPLETE_SAVING: "Start sparing",
    BUTTON_INFO: "Meir info",
    BUTTON_LESS_INFO: "Mindre info",
    BUTTON_FINISH: "Skriv ut oppsummering",
    BUTTON_RETURN: "Tilbake til mi oversikt",
    BUTTON_LOG_IN: "Logg inn og fortsett",

    VALUE_OPTIMISTIC: "Optimistisk verdi",
    VALUE_EXPECTED: "Forventa verdi",
    VALUE_CONSERVATIVE: "Konservativ verdi",
    AMOUNT_DEPOSITED: "Innskote beløp",
    NOK: "NOK",
    SHARES: "Aksjar",
    INTEREST: "Renter",

    // Validation
    VALIDATION_NO_SELECTION: "Før du går vidare må du velje eit alternativ",
    VALIDATION_MIN_AMOUNT: "Minimum månedleg sparebeløp er 250 NOK",
    VALIDATION_START_AMOUNT: "Oppstartsbeløp må være høgare enn 250 NOK, eller 0 NOK",
    VALIDATION_RETURNING: "Du har tidligere verdier lagret. Ønsker du å fortsette med dem, eller starte på nytt?",

    // User messages
    MESSAGE_NO_FUND_FOR_YOU: "Fondssparing er nok ikkje det beste alternativet for deg.",
    MESSAGE_REMOVE_DEBT: "Vi anbefalar at du betalar ned på gjeld før du startar fondssparing",
    MESSAGE_CREDIT:
      "Med dyr gjeld som ikkje er planlagt nedbetalt kan vi ikkje råde deg til å spare i fond framfor å nedbetale denne gjelda. Oppdater vala dine eller avslutt.",
    MESSAGE_CHANGE_REPLY: "Endre svar",
    MESSAGE_CONTINUE: "Fortsett",
    MESSAGE_RESTART: "Start på nytt",
    MESSAGE_QUIT: "Avslutt sparerobot",
    MESSAGE_LOADING: "Laster data...",

    // Error
    ERROR: "Noe gikk galt",
    ERROR_FETCH: "Klarte ikkje hente ut spareforslag",
    ERROR_CONTINUE: "Kan ikkje gå vidare utan forslag",
    ERROR_COST: "Klarte ikkje hente ut kostnadar for dette spareforslaget",
    ERROR_ACCOUNT: "Klarte ikkje å hente ut konto",
    ERROR_AGREEMENT: "Klarte ikkje å opprette spareavtalen. Kontakt ein rådgjevar",

    // Startpage
    PAGE_0_HEADER: "Velkommen til spareroboten",
    PAGE_0_SUBHEADER:
      "Sparinga du vel å starte kan avsluttast ved ønskje, og pengane kan takast ut. Sparinga blir ikkje starta før du blir bedt om endeleg godkjenning i siste bilde.",
    PAGE_0_SUBHEADER_2:
      "Robotrådgjevaren skal autoriseras av Finansnæringas autorisasjons­ordningar innan juli 2024. Autorisasjonen skal gje ein tryggleik for at løysinga ikkje bare oppfyller lovkrav, men også finansnæringas bransjenorm God Skikk. Les meir her: www.finaut.no/robot",
    PAGE_0_BUTTON_TEXT_ACTIVE: "Meir info",
    PAGE_0_INFO_TEXT_A: "Du kan til eit kvart tidspunkt gå tilbake og endre tidlegare svar",
    PAGE_0_INFO_TEXT_H: "Om du vil velge fond sjølv avsluttar du roboten og logger på bankens fondsplattform",
    PAGE_0_INFO_TEXT_B: "Blir du usikker kan du alltid ringe og få snakke med en rådgjevar.",
    PAGE_0_INFO_TEXT_C:
      "Dersom opplysningane du gjev er ufullstendige eller ikkje er riktige, vil du få spareråd som ikkje er egna for deg.",
    PAGE_0_INFO_TEXT_D:
      "Investeringsrådgjeving via sparerådgjevaren gjev personlege råd om fondssparing som kan brukast for spareavtale og enkeltinnskot. Produkttilråding vil vere basert på fond som blir tilbydd i Norne (150 fond), og vil omfatte aktive og passive aksjefond, kombinasjonsfond og rentefond. Vi yter ikkje-uavhengig rådgjeving.",
    PAGE_0_INFO_TEXT_E:
      "Ønskjer du risikofri sparing, eller har kredittkort eller forbrukslån med høge renter, bør du ikkje spare i fond",
    PAGE_0_INFO_TEXT_F:
      "Robotrådgjevaren automatiserte råd er basert på informasjonen du oppgir. Personopplysningar nyttas ikkje til profilering. Norne innhentar ikkje informasjon om deg som kunde fra andre datakjelder.",
    PAGE_0_INFO_TEXT_G:
      "Det føretas ikkje årleg dugleiksvurdering av rådane som blir gjeve. Seinare endring av sparing skjer på eiget initiativ.",
    PAGE_0_INFO_TEXT_I:
      "Hovedsakelig vil du verte anbefalt porteføljar av fond satt saman i eit fond-i-fond. Dersom du føretrekker enkeltfond handles dei direkte i portalen ",

    // Page 1
    PAGE_1_HEADER: "Erfaring",
    PAGE_1_SUBHEADER: "Har du erfaring med fond eller aksjar?",
    PAGE_1_A: "Eg har ingen erfaring med aksjar eller fond",
    PAGE_1_B: "Eg har handla litt aksjar eller fond dei siste fem åra",
    PAGE_1_C: "Eg handlar aksjar eller fond regelmessig",

    // Page 2
    PAGE_2_HEADER: "Relevant utdanning eller jobb",
    PAGE_2_SUBHEADER: "Har du utdanning eller jobb som er relevant for fond eller aksjar?",
    PAGE_2_A: "Nei",
    PAGE_2_B: "Ja, eg har utdanning eller arbeidserfaring som gjere at eg forstår fond",

    // Page 3
    PAGE_3_HEADER: "Oppfrisking",
    PAGE_3_SUBHEADER: "Ønskjer du friske opp eller lære meir om fond og aksjehandel?",
    PAGE_3_A: "Nei takk, eg har god oversikt",
    PAGE_3_B: "Ja, eg vil lære meir",
    PAGE_3_INFO_TEXT_A_HEADER: "Aksje",
    PAGE_3_INFO_TEXT_A:
      "Ein eigardel av eit selskap. Eig du 1% av aksjane i eit selskap, har du rett på 1% av alt selskapet tener og eig.",
    PAGE_3_INFO_TEXT_B_HEADER: "Obligasjon",
    PAGE_3_INFO_TEXT_B:
      "Ein del av selskapet si gjeld. Eksempel: Equinor låna ikkje berre pengar i banken, men dei låna også direkte med deg som investor. Eig du litt av ein obligasjon, skuldar selskapet deg pengar og betalar rente som på eit vanleg banklån.",
    PAGE_3_INFO_TEXT_C_HEADER: "Fond",
    PAGE_3_INFO_TEXT_C:
      "Det finnast fleire typar fond: aksjefond, rentefond, eller ein kombinasjon av desse. I motsetning til å eige ein enkeltaksje består eit fond av mange forskjellige aksjar og/eller rentepapir/obligasjonar, noko som gjere at risikoen blir fordelt og difor lågare. Fonda har strenge reglar for å beskytte deg som investor.",
    PAGE_3_INFO_TEXT_D_HEADER: "Aksjefond",
    PAGE_3_INFO_TEXT_D:
      "Eit verdipapirfond der minimum 80 prosent av kapitalen er investert i aksjemarknaden. Aksjefond kan investere heile verda, men kan også ha fokus på geografi (eks Norge), bransje (eks teknologi) eller eit tema (eks miljø).",
    PAGE_3_INFO_TEXT_D_LINK: "For meir informasjon om aksjefond, klikk her.",
    PAGE_3_INFO_TEXT_E_HEADER: "Rentefond",
    PAGE_3_INFO_TEXT_E:
      "Kan delast i to undergrupper; 1) Pengemarknadsfond som har kort bindingstid (under eitt år), og som har relativt låg risiko. 2) Obligasjonsfond som har lenger enn eitt år bindingstid, og har høgare risiko. Med ein høgare risiko vil obligasjonsfond vanlegvis gje høgare avkastning enn pengemarknadsfond over tid.",
    PAGE_3_INFO_TEXT_E_LINK: "For meir informasjon om rentefond, klikk her.",
    PAGE_3_INFO_TEXT_F_HEADER: "Kombinasjonsfond",
    PAGE_3_INFO_TEXT_F: "Er ei blanding av rentefond og aksjefond.",
    PAGE_3_INFO_TEXT_F_LINK: "For meir informasjon om kombinasjonsfond, klikk her.",
    PAGE_3_INFO_TEXT_G_HEADER: "Risiko",
    PAGE_3_INFO_TEXT_G:
      "For å oppnå høgare avkastning enn vanleg bankinnskot må ein ta risiko. Når ein tek høgare risiko, risikerer ein å tape pengar. Høgare risiko, og mogleg høgare avkastning, kan du som kunde oppnå gjennom å investere i fond: aksjefond, rentefond eller ein kombinasjon av desse. Det er fleire typar risiko knytt til dette, men hovudelementet er at verdien svingar, og ein kan oppleve at verdien av investeringa er lågare når du treng pengane, enn det den var då du kjøpte den.",
    PAGE_3_INFO_TEXT_G_2:
      "Risikoen knytt til investering i fond vert redusert sidan eit fond spreiar investeringane på mange ulike enkeltpapir. Eit fond kan investere i spesifikke sektorar(sektor risiko), eller i spesifikke geografiske regionar (geografisk risiko). Slike fond vil ha høgare risiko enn globale fond.",
    PAGE_3_INFO_TEXT_G_LINK: "For meir informasjon om risiko, klikk her.",
    PAGE_3_INFO_TEXT_H_HEADER: "Fond-i-fond",
    PAGE_3_INFO_TEXT_H:
      "Eit fond-i-fond er ein investeringsstruktur der eit fond investerer i andre fond, i stedet for direkte i aksjar eller obligasjonar. Dette gir investor breiare diversifisering, tilgang til ulike marknader og forvaltningstyper, samtidig som forvaltere håndterer investeringsbeslutningane- både utskiftninger og rebalanseringer. Fond-i-fond kan være eit godt valg for dei som ønsker ein enkel og profesjonell tilnærming til investering. Norne sine fond-i-fond er forvaltet av Norne Securities med Alfred Berg Kapitalforvaltning som administrator.",

    // Page 4
    PAGE_4_HEADER: "Sparemål og planlagt lengde",
    PAGE_4_SUBHEADER:
      "Kva sparar du til og kor lenge ser du for deg å spare? Du kan sjølvsagt avbryte sparinga og ta ut pengane når du ønskjer.",
    PAGE_4_INFO_BOX:
      "For å tilpasse risikoen i sparinga di: Dess lenger fram i tid du ser for deg å bruke pengane, dess høgare risiko anbefaler vi.",
    PAGE_4_A: "1-3 ÅR",
    PAGE_4_A_1: "Planlagt investering eller forbruk (døme: reise, bustad, oppussing)",
    PAGE_4_B: "3-7 ÅR",
    PAGE_4_B_1: "Spare for å spare: auka økonomisk handlingsfridom fram i tid",
    PAGE_4_C: "7-10 ÅR",
    PAGE_4_C_1: "Sparing til pensjon eller spare for å spare",
    PAGE_4_D: "10+ ÅR",
    PAGE_4_D_1: "Sparing til pensjon eller spare for å spare",
    PAGE_4_E: "Gå til IPS rettleiar - bunden pensjonssparing til 62 med skattefrådrag - gå hit.",
    PAGE_4_BUTTON_TEXT_ACTIVE: "Lær meir om horisont og avkastning",
    PAGE_4_INFO_TEXT_A:
      "Aksjar kan svinge mykje i verdi, derfor vil sparelengda påverke kor stor del aksjar ein burde ha i porteføljane. Søylene under viser historisk årleg avkastning (siste 25 år) for verdsindeksen i norske kroner med 1, 5 og 10 år lengde på sparinga.",
    PAGE_4_INFO_TEXT_B: "1 år",
    PAGE_4_INFO_TEXT_C:
      "Med ei eingongsplassering i eit globalt aksjefond kunne historisk sett, eit enkelt år, med maks uflaks, gje ei negativ avkastning på -42%. Med maks flaks på eitt år kunne avkastninga vore 45% pluss.",
    PAGE_4_INFO_TEXT_D: "5 år",
    PAGE_4_INFO_TEXT_E:
      "Om du hadde investert i minimum 5 år, ville avkastninga aldri vore dårlegare enn -6% i året, og den beste perioden ville gitt 24% årleg. ",
    PAGE_4_INFO_TEXT_F: "10 år",
    PAGE_4_INFO_TEXT_G:
      "På 10 år er den dårlegaste perioden -3% pr år (-26% over heile perioden), og den beste 13% pr år (+239% over perioden).",
    PAGE_4_INFO_TEXT_H:
      "Historisk avkasting gir som kjent ingen garanti for framtidig avkastning, men det gir deg ein indikasjon på storleiken i variasjonar og viktigheita av å vere ein langsiktig investor.",
    PAGE_4_CHART_HISTORIC: "HISTORISK AVKASTNING",
    PAGE_4_CHART_BEST_PERIOD: "Beste periode",
    PAGE_4_CHART_WORST_PERIOD: "Dårlegste periode",
    PAGE_4_CHECKBOX_A: "Sparingen vil utgjere ein mindre del av min totale økonomi",
    PAGE_4_CHECKBOX_B: "Sparingen vil vere ein stor del av min totale økonomi",
    PAGE_4_DATA_ONE: "1 ÅR",
    PAGE_4_DATA_FIVE: "5 ÅR",
    PAGE_4_DATA_TEN: "10 ÅR",

    // Page 5
    PAGE_5_HEADER: "Risikovilje",
    PAGE_5_INFO_HEADER:
      "Kor offensiv eller forsiktig du skal vere med sparinga avhenger mykje av alder, kor godt økonomien din toler svingingar i oppsparte midlar, og kor lenge du planlegg å spare. Tommelfingerregelen er at du kan vere meir offensiv jo lenger du skal spare.",
    PAGE_5_HEADER_LINK: "Les og lær meir om sparing og fond her (ekstern lenke)",
    PAGE_5_SUBHEADER: "Kvar kjenner du deg best igjen?",
    PAGE_5_A: "VELDIG FORSIKTIG:",
    PAGE_5_A_1: "Eg ønskjer ikkje å ta risiko på sparepengane mine og vil ha dei på ein bankkonto.",
    PAGE_5_B: "FORSIKTIG: BEVARING AV FORMUE",
    PAGE_5_B_1:
      "Nokre svingingar tåler eg for å få litt høgare avkastning på pengane over tid, og slik beskytte verdiane mot inflasjon.",
    PAGE_5_C: "BALANSERT: AUKE FORMUEN GJENNOM AVKASTNING",
    PAGE_5_C_1: "Økonomien min er robust og eg toler svingingar godt for å oppnå høgare avkastning.",
    PAGE_5_D: "OFFENSIV HØG AVKASTNING PÅ PENGANE FOR Å TENE MEST MOGLEG.",
    PAGE_5_D_1: "Vere med på verdiskaping i samfunnet. Eg både toler og er forberedd på store svingningar.",
    PAGE_5_TOGGLE: "Lær meir om svingningar",
    PAGE_5_INFO_TEXT_A:
      "Å ha alle pengane plassert i aksjemarknaden har historisk gitt høgast avkastning. Men det har også gitt størst svingingar og mest ubehag. Under finn du utviklinga for Oslo Børs siste 25 år, saman med pengemarknadsrenta (Nibor), samt forskjellige kombinasjonar av dei to.",
    PAGE_5_INFO_TEXT_B:
      "Ei rein renteportefølje skal gje litt meir avkasting enn banksparing, men med nokre variasjonar. Ein bankkonto går aldri i minus, men rentefond har i periodar negativ avkasting. Kombinasjonar av renter og aksjar gjer då at ein kan få ei sparing som svingar akkurat så mykje som ein synast er behageleg.  Grafane viser utviklinga med alt plassert i aksjar, alt i lågrisiko-renter og ein kombinasjon av dei to. Men grunnregelen er at jo meir svingingar ein får, dess meir bør ein forvente i avkastning.",
    PAGE_5_INFO_TEXT_C:
      "Grafen som illustrerer renter over er pengemarkedsrenten, som er tilnærmet risikofri. Rentefondene du vil få anbefalt svinger mer enn dette.",
    PAGE_5_GRAPH_STOCKS: "100% Aksjar",
    PAGE_5_GRAPH_BALANCED: "50/50",
    PAGE_5_GRAPH_INTEREST: "100% Renter",

    SHORT_HORIZON_USER_MESSAGE:
      "Kort horisont kombinerer dårlig med ønsket om høg avkasting: man risikerer å måtte selge på ugunstige tidspunkt. Les gjerne mer om avkastning og risiko ",
    SHORT_HORIZON_GO_BACK: "Gå tilbake",
    SHORT_HORIZON_CONTINUE: "Fortsett",

    // Page 6
    PAGE_6_HEADER: "Is i magen?",
    PAGE_6_INFO_HEADER:
      "Din spareplan er snart klar, men for å gje deg dei beste råda om sparing, treng vi å vite litt meir om deg.",
    PAGE_6_SUBHEADER_2: "Om det kjem eit kraftig fall på børsen, ville du",
    PAGE_6_A: "Seld for å minimere tap",
    PAGE_6_B: "Halde deg roleg, gjort ingenting",
    PAGE_6_C: "Nytta sjansen til å kjøpe ekstra",
    PAGE_6_BUTTON_TEXT: "Meir om marknadsfall",
    PAGE_6_INFO_TEXT:
      "Om ein hadde spart på Oslo Børs ville ein dei siste 40 åra i periodar opplevd store fall. Mange selskap har gått konkurs, men med sparing i fond eig du litt i mange forskjellige selskap og slik få ein del av samfunnet sin verdiskaping. Historisk ser ein at fall har bydd på moglegheiter. Marknadsfall har også ein tendens til å skje raskare enn oppgangsperiodar. I snitt har Oslo Børs over 12% årleg avkastning sidan starten av 80-tallet.",

    // Page 7
    PAGE_7_HEADER: "Bærekraft",
    PAGE_7_INFO_HEADER:
      "Vi har fokus på bærekraft og alle forvalterne som velges har klare regler for bærekraft i forvaltningen. Samtidig er det forskjell på fond og hvordan de investerer.",
    PAGE_7_SUBHEADER:
      "Ønsker du økt bærekraftfokus i din portefølje, og er du komfortabel med at det kan gi både negativ og positiv effekt på avkastningen din?",
    PAGE_7_LINK_TEXT: "Les mer på vår bærekraftsside",

    PAGE_7_INFO_TEXT_1_HEADER: "Bærekraft",
    PAGE_7_INFO_TEXT_1_A:
      "Bærekraftsfaktorer er miljøspørsmål, sosiale spørsmål og personalspørsmål samt spørsmål knyttet til respekt for menneskerettighetene og bekjempelse av korrupsjon. Økt bærekraftfokus i din portefølje kan gi både negativ og positiv effekt på avkastningen.",
    PAGE_7_INFO_TEXT_1_B: "Eksempel på positive effekter:",
    PAGE_7_INFO_TEXT_1_C: "- Lavere risiko for miljø- og sosiale utfordringer, og slik fall i aksjekurser",
    PAGE_7_INFO_TEXT_1_D: "- Vekstmuligheter",
    PAGE_7_INFO_TEXT_1_E: "Eksempel på negative effekter:",
    PAGE_7_INFO_TEXT_1_F: "- Begrenser utvalget av investerbare selskaper og dermed en mindre diversifisert portefølje",
    PAGE_7_INFO_TEXT_1_G: "- Høyere kostnader ved å investere bærekraftig",
    PAGE_7_OPTIONS_1_A: "Bærekraft er viktig for min portefølje.",
    PAGE_7_OPTIONS_1_B: "Jeg forholder meg nøytral til bærekraft.",

    PAGE_7_INFO_TEXT_2_HEADER: "Sustainable Finance Disclosure Regulation (SFDR)",
    PAGE_7_INFO_TEXT_2_A: "Klassifisering av alle fond i EU for å beskrive bærekraftshensyn i investeringsbeslutningar.",
    PAGE_7_INFO_TEXT_2_B: "Artikkel 9-fond",
    PAGE_7_INFO_TEXT_2_C: "Bærekraftige investeringar som overordnet mål. Gjerne omtalt som mørkegrøne fond.",
    PAGE_7_INFO_TEXT_2_D: "Artikkel 8-fond",
    PAGE_7_INFO_TEXT_2_E: "Fond som fremmer miljømessige eller sosiale egenskaper. Gjerne omtalt som lysegrøne fond.",
    PAGE_7_INFO_TEXT_2_F: "Artikkel 6-fond",
    PAGE_7_INFO_TEXT_2_G:
      "Hensyntar bærekraftsrisiko, men fremmer ikke miljømessige eller sosiale egenskaper. Det finansielle produktets underliggande investeringar tar ikkje omsyn til EU-kriteria for miljømessig bærekraftige økonomiske aktivitetar.",
    PAGE_7_OPTIONS_2_A: "SFDR er viktig for min portefølje.",
    PAGE_7_OPTIONS_2_B: "Jeg forholder meg nøytral til SFDR.",

    PAGE_7_INFO_TEXT_3_HEADER: "Principal Adverse Impacts (PAI)",
    PAGE_7_INFO_TEXT_3_A:
      "Investeringenes negative påvirkning på bærekraftsfaktorer, herunder klimagassutslipp, naturmangfold, utslipp, avfall, sosiale forhold (barnearbeid, sosial dumping, jobbsikkerhet, HMS, diskriminering), kontroversielle våpen, hensyn til urbefolkning, bruk av vann og landområder.",
    PAGE_7_INFO_TEXT_3_B:
      "Regelverket for PAI er under utvikling og det føreliggjar per i dag ikkje fullgode data for alle dei negative konsekvensane for bærekraftsfaktorar (PAI). Det lar seg difor ikkje gjøre å fullt ut tilpassa anbefalingar til investors bærekraftspreferansar før kvaliteten på klassifiseringssystemet og datatilgangen blir mer presis. Norne vil likevel ta omsyn til kundens preferansar så langt det til enkvar tid er mogeleg og for øvrig i samsvar med vår bærekraftspolicy.",
    PAGE_7_OPTIONS_3_A: "Principal Adverse Impacts er viktig for min portefølje",
    PAGE_7_OPTIONS_3_B: "Jeg forholder meg nøytral til Principal Adverse Impacts.",

    PAGE_7_INFO_TEXT_4_HEADER: "EU-taksonomi",
    PAGE_7_INFO_TEXT_4_A:
      "Taksonomien er eit klassifiseringssystem som skal gjøre det mugleg å si kva investeringar som er bærekraftige, ved at foretak må rapportere korleis dei presterer på seks ulike miljømål:",
    PAGE_7_INFO_TEXT_4_B: "1. Redusera og førebyggje klimautslipp",
    PAGE_7_INFO_TEXT_4_C: "2. Klimatilpasning",
    PAGE_7_INFO_TEXT_4_D: "3. Bærekraftig bruk og beskyttelse av vann- og marine ressursar",
    PAGE_7_INFO_TEXT_4_E: "4. Overgang til en sirkulær økonomi, avfallsførebyggjing og gjenvinning",
    PAGE_7_INFO_TEXT_4_F: "5. Førebyggjing og kontroll av forureining",
    PAGE_7_INFO_TEXT_4_G: "6. Vern og restaurering av naturmangfold og økosystemar",
    PAGE_7_INFO_TEXT_4_H:
      "For å være bærekraftig må aktiviteten både bidra til minst eit av de seks målane og ikkje forårsake betydeleg på noen av de andre målene.",
    PAGE_7_INFO_TEXT_4_I:
      "Fond skal rapportere hvor stor andel av fondets investeringer som skal være i økonomiske aktiviteter som er miljømessig bærekraftige etter taksonomien. Taksonomien er under utvikling og kriteriene for alle miljømål er ennå ikke klare. Det er heller ikke etablert en beregningsmodell for andelen av fondets investeringer som er i samsvar med taksonomien. Selskapene som fondet investerer i, rapporterer i begrenset grad i hvilken grad deres aktiviteter er forenlig med EU-taksonomien. Vi vurderer derfor at det på nåværende tidspunkt ikke er mulig å gi pålitelig informasjon om hvor stor andel av porteføljens investeringer som skal være i økonomiske aktiviteter som er miljømessig bærekraftige etter taksonomien.",
    PAGE_7_OPTIONS_4_A: "EU-taksonomi er viktig for min portefølje.",
    PAGE_7_OPTIONS_4_B: "Jeg forholder meg nøytral til EU-taksonomi.",

    // Page 8
    PAGE_8_HEADER: "Fri likviditet",
    PAGE_8_SUBHEADER:
      "Kor mykje sit du att med kvar månad av lønn og andre inntekter, etter at alle utgifter er betalt og anna sparing er trekt?",
    PAGE_8_A: "Eg har meir utgifter enn inntekter",
    PAGE_8_B: "100 - 2 000,-",
    PAGE_8_C: "2 000 - 10 000,-",
    PAGE_8_D: "Over 10 000,-",

    // Page 9
    PAGE_9_HEADER: "Bankinnskot",
    PAGE_9_INFO_BOX: "Total fri finansiell formue er avgjerande for kor mykje svinginga ein toler",
    PAGE_9_SUBHEADER:
      "Kor mykje pengar har du på spare- og høgrentekonto i dag? Oppgje innskot i alle bankar, men hald innskot som BSU og pensjon utanfor",
    PAGE_9_A: "0 - 150 000,-",
    PAGE_9_B: "150 000 - 500 000,-",
    PAGE_9_C: "Over 500 000",

    // Page 10
    PAGE_10_HEADER: "Fond og aksjar",
    PAGE_10_INFO_BOX: "Total fri finansiell formue er avgjerande for kor mykje svingingar ein toler.",
    PAGE_10_SUBHEADER:
      "Kva er verdien på fond og aksjar som du allereie eig i dag? Hald bunden pensjonssparing i fond og aksjar utanfor",
    PAGE_10_SUB_QUESTION: "Kvifor spør vi om dette?",
    PAGE_10_INFO: "Total fri finansiell formue er avgjerande for kor mykje svinginga ein toler.",
    PAGE_10_CLOSE: "Lukk",
    PAGE_10_A: "0 - 150 000,-",
    PAGE_10_B: "150 000 - 500 000,-",
    PAGE_10_C: "Over 500 000",

    // Page 11
    PAGE_11_HEADER: "Gjeld og verdiar",
    PAGE_11_SUBHEADER:
      "Eg har meir verdiar enn gjeld. Verdiar er hus, hytte, bil og båt, samt bankinnskot og fond/aksjar. Studiegjeld kan utelatast frå reknestykket. ",
    PAGE_11_A: "Nei",
    PAGE_11_B: "Ja",
    PAGE_11_INFO: "Fondssparing er ikkje anbefalt om ein har dyr gjeld.",
    PAGE_11_CONFIRM:
      "Eg stadfestar at eg ikkje har forbrukslån eller kredittkortgjeld som er renteberande. Eventuelt: Dette vil vere nedbetalt før oppstart av fondssparing.",
    PAGE_11_REDIRECT: "Du vil no bli videresendt til innlogging før du kan fullføre spareroboten.",
    PAGE_11_REDIRECT_SAFARI:
      "Du vil no bli videresendt til innlogging før du kan fullføre spareroboten. Dei siste stegene av spareroboten gjennomføres via nettsidene til Norne Securities.",

    // Page 12
    PAGE_12_HEADER: "Risikonivå",
    PAGE_12_SUBHEADER: "Analysen viser at sparing med ein ",
    PAGE_12_SUBHEADER_B: " risikoprofil passar for deg",
    PAGE_12_ADJUST: "Juster ditt risikonivå",
    PAGE_12_CAREFUL: "Forsiktig",
    PAGE_12_BALANCED: "Balansert",
    PAGE_12_OFFENSIVE: "Offensiv",
    PAGE_12_CAREFUL_TEXT: "forsiktig",
    PAGE_12_BALANCED_TEXT: "balansert",
    PAGE_12_OFFENSIVE_TEXT: "offensiv",
    PAGE_12_EXTRA_OFFENSIVE_TEXT: "ekstra offensiv",
    PAGE_12_API_CAREFUL: "Careful",
    PAGE_12_API_BALANCED: "Balanced",
    PAGE_12_API_OFFENSIVE: "Offensive",
    PAGE_12_API_EXTRA_OFFENSIVE: "ExtraOffensive",

    // Page 13
    PAGE_13_HEADER: "Spareavtaledetaljar",
    PAGE_13_SUBHEADER:
      "Kor mykje ønskjer du å spare? Hugs at beløpet du vel ikkje er bindande, og kan ved eit kvart tidspunkt endrast etter eige ønskje. Månadleg sparebeløp bør vere del av månadleg overskot på brukskonto etter alle kostnadar og eventuell anna sparing. Om du er under 33 år og betalar skatt, bør BSU fyllast opp før fondssparing.",
    PAGE_13_SUBHEADER_INFO_QUESTION: "Kva er oppstartsbeløp?",
    PAGE_13_SUBHEADER_INFO:
      "Oppstartsbeløpet kan fordelast over ein ønskja periode og går som eit fast månadleg trekk. Første trekk er i morgon. Om du er kunde hjå Sparebanken 68gr, Aasen Sparebank, Selbu Sparebank, Nidaros Sparebank, Stads Stadsbygd Sparebank, Ørland Sparebank, Tolga-Os Sparebank, Sparebanken DIN, Drangedal Sparebank eller A & S Sparebank gjerast dette som eit eingongstrekk.",
    PAGE_13_START_AMOUNT: "OPPSTARTSBELØP",
    PAGE_13_START_AMOUNT_SPREAD: "OPPSTARTSBELØPET SKAL FORDELAST OVER",
    PAGE_13_MONTHLY_SAVING: "MÅNADLEG SPARING",
    PAGE_13_MONTHLY_AVERAGE: "Den gjennomsnittlege spareavtalen i fond er på kr. 1 000,-",
    PAGE_13_VALUE_OPTIMISTIC: "Optimistisk verdi",
    PAGE_13_VALUE_EXPECTED: "Forventa verdi",
    PAGE_13_VALUE_CONSERVATIVE: "Konservativ verdi",
    PAGE_13_VALUE_AMOUNT: "Innskote beløp",
    PAGE_13_A: "1 MND",
    PAGE_13_B: "3 MND",
    PAGE_13_C: "6 MND",
    PAGE_13_D: "12 MND",
    PAGE_13_E: "Eigendefinert",

    PAGE_13_REQUIREMENTS_HEADER: "Forutsetningar",
    PAGE_13_TEXT_A: "Forutsetninga på avkastninga som er brukt:",
    PAGE_13_TEXT_B: "Forventa verdi: aksjar 6,5% årleg, renter 3.5% årleg.",
    PAGE_13_TEXT_C: "Optimistisk verdi: aksjar 8,5% årleg, renter 4,5% årleg.",
    PAGE_13_TEXT_D: "Konservativ verdi: aksjer 3,75% årleg, renter: 2,5% årleg.",
    PAGE_13_TEXT_E:
      "Prognosane for framtidig avkastning er basert på historisk avkastning. Vi gjer merksam på at historisk avkastning ikkje er noko garanti for framtidig avkastning.",
    PAGE_13_TEXT_F:
      "Framtidig avkasting vil mellom anna avhenge av marknadsutviklinga, forvaltarens dyktigheit, fondet sin risiko, samt kostnadar knytt til forvaltning. Avkastninga kan bli negativ som følge av kurstap. Avkastninga er etter forvaltningshonoraret på 0,5% for aksjar og 0,3% for renter.",
    PAGE_13_TEXT_G: "Skatt og inflasjon er ikkje hensyntatt i beregningane.",
    PAGE_13_TEXT_H: "Plattformhonorar er ikkje hensyntatt i beregningane.",

    PAGE_13_NOT_ENOUGH_LIQUIDITY_TEXT: "Beløpet er høgare enn oppgitt fri likviditet. Vil du gå tilbake og endre tidligere svar?",
    PAGE_13_NOT_ENOUGH_SAVINGS_TEXT: "Beløpet er høgare enn oppgitt bankinnskudd. Vil du gå tilbake og endre tidligere svar?",
    PAGE_13_GO_BACK_TO_LIQUIDITY: "Gå tilbake til fri likviditet",
    PAGE_13_CONTINUE: "Fortsett",
    PAGE_13_GO_BACK_TO_SAVINGS: "Gå tilbake til bankinnskudd",
    PAGE_13_ABOVE_MAX_AMOUNT_TEXT:
      "Begrensning på 1 mnok og ta kontakt for utvidet gjennomgang, eller gjør tegning direkte i portalen",
    PAGE_13_ABOVE_MAX_AMOUNT_BUTTON_TEXT: "Lukk",

    PAGE_13_NUM_YEARS: "Grafen viser år med sparing:",
    PAGE_13_SAVING_AMOUNT: "Forventet sparebeløp per",
    PAGE_13_YEAR: "År",
    PAGE_13_SAVINGS_RESULT: "Slik ser det ut med kr. ",
    PAGE_13_SAVINGS_RESULT_B: ",- i oppstartsbeløp og kr. ",
    PAGE_13_SAVINGS_RESULT_C: ",- i månadleg sparing. Skatt og inflasjon er ikkje hensyntatt i simuleringa.",
    PAGE_13_SAVINGS_DISCLAIMER:
      "Fremstillingen er stilisert og tar ikkje opp i seg svingningane i verdiene som vil kome. Spareverdien vil ikkje utvikle seg stabilt opp som her.",

    PAGE_13_TEST_WITH_RISK_PROFILE_LABEL: "Prøv med ulike risikoprofilar (dette endrar ikkje spareforslaget)",
    PAGE_13_TEST_WITH_RISK_CAREFUL_LABEL: "Forsiktig",
    PAGE_13_TEST_WITH_RISK_BALANCED_LABEL: "Balansert",
    PAGE_13_TEST_WITH_RISK_OFFENSIVE_LABEL: "Offensiv",
    PAGE_13_TEST_WITH_RISK_EXTRA_OFFENSIVE_LABEL: "Ekstra offensiv",

    // Page 14
    PAGE_14_HEADER: "Spareforslag",
    PAGE_14_KIID_HEADER: "KIID",
    PAGE_14_INFO_TEXT:
      "Delar av, eller alle plasseringar som gjerast gjennom fond i fond (Norne Aksje, Kombi eller Rente), gjer portefølja mest mogleg vedlikehaldsfri. Oppfølging, rapportering og skatt blir enklare. Under finn du underliggande fond.",
    PAGE_14_PORTFOLIO: "Anbefalt portefølje",
    PAGE_14_COLUMN_HEADER: "Type",
    PAGE_14_COLUMN_HEADER_A: "Fond",
    PAGE_14_COLUMN_HEADER_B: "Berekraftsrating",
    PAGE_14_COLUMN_HEADER_C: "SFDR",
    PAGE_14_COLUMN_HEADER_D: "Fordeling",
    PAGE_14_COLUMN_HEADER_E: "KIID",

    // Page 15
    PAGE_15_HEADER: "Forventa resultat",
    PAGE_15_ANNUAL: "Årlege kostnadar",
    PAGE_15_DECADE: "Kostnadar over 10 år",
    PAGE_15_COST: "Kostnad",
    PAGE_15_PERCENTAGE: "Prosent",
    PAGE_15_PRICE: "Pris",
    PAGE_15_DEPOT: "Depotkostnadar",
    PAGE_15_COMMISION: "Forvaltningshonorar",
    PAGE_15_VALUE_TOTAL: "Porteføljeverdi",
    PAGE_15_COMMISIONER: "Til forvaltar",
    PAGE_15_TRANSACTION_COST: "Transaksjonskostnadar",
    PAGE_15_COST_BANK: "Til bank/Norne",
    PAGE_15_TOTAL_COST: "Total kostnad",
    PAGE_15_TOTAL: "Totalt",
    PAGE_15_EXPECTED_VALUE: "Forventa verdi etter kostnadar",
    PAGE_15_EXPECTED_PROFIT: "Forventa avkastning er",
    PAGE_15_PERCENTAGE_SIGN: "%",
    PAGE_15_BEFORE_COST: "før kostnad, ",
    PAGE_15_AFTER_COST: "etter kostnad",
    PAGE_15_AND: "og ",
    PAGE_15_FINANCE_PORTAL_PRE_TEXT: "Du kan samanlikne priser på ",
    PAGE_15_FINANCE_PORTAL_LINK_TEXT: "finansportalen.no",

    // Page 16
    PAGE_16_HEADER: "Start sparing",
    PAGE_16_NAME: "NAVN PÅ SPAREAVTALEN",
    PAGE_16_NAME_DEFAULT: "Min spareavtale",
    PAGE_16_DATE: "Vel trekkdato",
    PAGE_16_ACCOUNT: "Vel kva fondskonto fondsdelane skal gå til",
    PAGE_16_ACCOUNT_DEFAULT: "VEL KONTO",
    PAGE_16_DEPOSIT: "Vel kva bankkonto pengane skal trekkast frå",
    PAGE_16_DEPOSIT_DEFAULT: "BANKKONTOAR",
    PAGE_16_DEPOSIT_WARNING: "Klarar ikkje å hente ut bankkontoar frå Evry",
    PAGE_16_CREATE_ASK: "Opprett ny ASK",
    PAGE_16_A: "Sparerobot er gjennomført på eigehand",
    PAGE_16_B: "Sparerobot er gjennomført saman med rådgjevar",
    PAGE_16_DATE_A: "1.",
    PAGE_16_DATE_B: "10.",
    PAGE_16_DATE_C: "15.",
    PAGE_16_DATE_D: "25.",
    PAGE_16_ADVISOR: "Hvilken rådgjevar gjennomførte spareroboten med deg?",
    PAGE_16_INFO_BOX:
      "Dette spareforslaget krev at ein plasserer fonda på fondskonto. Rentefond og kombinasjonsfond med mindre enn 80% aksjeandel kan ikkje plasserast på Aksjesparekonto. Fondskonto har ikkje utsatt beskatning ved realisasjoner.",
    PAGE_16_FOOTER_HEADER: "Du er nesten ferdig. Klikk start sparing for å opprette din nye spareavtale.",
    PAGE_16_FOOTER_TEXT: "Husk at spareavtalen kan stoppes når som helst. Engangstegninger kan stoppes frem til midnatt i dag.",

    // Page 17
    PAGE_17_HEADER: "",
    PAGE_17_SUBHEADER: "Gratulerer med eit klokt val. No kan du lene deg tilbake, slappe av, og la pengane jobbe for deg.",
    PAGE_17_INGRESS:
      "Oppsummeringen er også sendt til din postkasse i spareportalen. Dersom du ikke ønsker elektronisk kommunikasjon, ta kontakt med kundeservice.",
    PAGE_17_START_HEADER: "Oppstartsavtale",
    PAGE_17_START_HEADER_ONE_TIME: "Engangsinnskudd",
    PAGE_17_PLACEMENT: "Spares til fondskonto: ",
    PAGE_17_WITHDRAW: "Trekkonto: ",
    PAGE_17_FUND: "Fond",
    PAGE_17_AMOUNT: "Månedlig sparebeløp",
    PAGE_17_AMOUNT_STARTUP: "Oppstartsbeløp:",
    PAGE_17_DATE_START: "Dato frå:",
    PAGE_17_DATE_STOP: "Dato til:",
    PAGE_17_NO_DATE: "Inga sluttdato",
    PAGE_17_SUITABILITY_HEADER: "Egnethetserklæring",
    PAGE_17_BUTTON_PRIMARY: "Skriv ut oppsummering",
    PAGE_17_BUTTON_SECONDARY: "Tilbake til mi oversikt",

    PAGE_17_AVTALE_GIRO_ERROR:
      "AvtaleGiro ble ikkje oppretta, du må opprette avtaleGiro manuelt i din nettbank til kontonummer $KONTONUMMER med KID $KID",

    // Create ASK
    ASK_HEADING: "Opprett Aksjesparekonto (ASK)",
    ASK_CORPORATE_ERROR_TEXT: "ASK er ikkje tilgjengelig for bedrifter",
    ASK_BANK_LABEL: "Velg bank",
    ASK_BANK_PLACEHOLDER: "Velg bank",
    ASK_CREATE_MISSING_BANK_ERROR: "Du må velge bank",

    ASK_TERMS_LINK: "Les vilkår for oppretting av aksjesparekonto",
    ASK_TERMS_HEADER: "Avtale om Aksjesparekonto",
    ASK_TERMS_1:
      "Mellom Norne Securities AS (Føretaket) og Kunden er det inngått avtale om Aksjesparekonto. Avtalen kjem i tillegg til eksisterande Kundeavtale med ",
    ASK_TERMS_1_A: "(Banken) og Kundeavtale inngått mellom Føretaket og Kunden.",
    ASK_TERMS_2:
      "Ved signering av Kundeavtalen har Kunden også akseptert Føretakets Forretningsvilkår. Banken har innsynsrett i kunden sin Aksjesparekonto.",
    ASK_TERMS_3: "Kunden stadfester at Føretaket har gitt informasjon om: ",
    ASK_TERMS_4: "- kva midlar som kan inngå på kontoen",
    ASK_TERMS_5: "- relevante skattereglar",
    ASK_TERMS_6: "- kostnader knytt til kontoen",
    ASK_TERMS_7: "- at det ikkje renter på inneståande kontantar",
    ASK_TERMS_8: "- forholdet til innskotsgarantiordninga",

    ASK_TERMS_9: "1. Kva avtalen gjelder",
    ASK_TERMS_10:
      "Avtalen gjeld oppretting av Aksjesparekonto, føring av konto og oppbevaring av finansielle instrument og kontantar på kontoen. Innskot på kontoen kan brukast til å investere i børsnoterte aksjar, børsnoterte eigenkapitalbevis og andelar i aksjefond i Norge («Investeringsuniverset»). Aksjesparekontoen kan med andre ord ikkje nyttast for handel i «exchange traded notes» (ETN), derivat, obligasjonar eller rentefond. Kombinasjonsfond må ha en aksjedel på meir enn 80% for å inngå i ordninga. Aksjedelen bereknast ved inntektsårets start. Gevinstar ved realisasjon av finansielle instrumenter i Investeringsuniverset skattleggast ikkje i samband med realisasjonen, men først når ein tek midlane ut av kontoen. Mottatt utbytte blir derimot skattlagt fortløpande. Ved overføring til Aksjesparekontoen vil inngangsverdien vidareførast med kontinuitet. Ved uttak frå kontoen oppfattast det som Kunden sitt innskot som det som takast ut først. Uttak ut over det som er satt inn på kontoen, skattleggast som aksjeinntekt hos Kunden.",

    ASK_TERMS_11: "2. Oppretting av Aksjesparekonto",
    ASK_TERMS_12: "Føretaket har oppretta ein Aksjesparekonto for Kunden på dei vilkåra som framkjem av Avtalen",

    ASK_TERMS_13: "3. Føring og vedlikehald av Aksjesparekontoen",
    ASK_TERMS_14: "Kontoen gir dei opplysningane som til ei kvar tid krevjast etter regelverket for Aksjesparekonto, for tiden: ",
    ASK_TERMS_14_B: "- innskot på kontoen",
    ASK_TERMS_14_C: "- oversikt over finansielle instrumenter ",
    ASK_TERMS_14_D: "- kontantbehaldning",
    ASK_TERMS_14_E: "- unytta skjermingsfrådrag frå tidligare år",
    ASK_TERMS_14_F: "- skjermingsgrunnlag",
    ASK_TERMS_14_G: "- berekna skjermingsfrådrag for skattleggingsperioden",
    ASK_TERMS_14_H: "- kontoens marknadsverdi ved årsskiftet",
    ASK_TERMS_14_I: "- mottatt utbytte",
    ASK_TERMS_14_J: "Føretaket sørga for oppdatering av opplysningane ved endringar.",

    ASK_TERMS_15: "4. Oppbevaring av kontante midlar",
    ASK_TERMS_16: "Kunden si kontantbehaldning oppbevarast på Føretaket sin klientkonto.",

    ASK_TERMS_17: "5. Oppbevaring av finansielle instrument",
    ASK_TERMS_18:
      "Finansielle instrument som er registrert i Verdipapirsentralen (VPS) vil bli oppbevart på Kunden sin VPS-konto merka ASK. Ved inngåing av denne avtalen har Kunden gitt Føretaket handelsfullmakt på denne kunden, og Føretaket er Kunden sin kontoførar For finansielle instrument som ikkje er registrert i VPS, for eksempel aksjefondsdelar, har Føretaket oppretta ein nominee-konto i Føretaket sitt namn hos fondsforvaltningsselskapet og Kunden sine aksjefondsdeler er overført frå Kunden sin konto i andelseigarregisteret til Føretaket sin nominee-konto.",

    ASK_TERMS_19: "6. Skatterapportering",
    ASK_TERMS_20:
      "Føretaket er ansvarleg for skatterapportering av aksjesparekontoen. Føretaket vil rapportere følgande opplysningar til Skatteetaten:",
    ASK_TERMS_20_B: "- Innskota (inngangsverdien) ",
    ASK_TERMS_20_C: "- Unytta skjermingsfrådrag frå tidligere år ",
    ASK_TERMS_20_D: "- Skjermingsgrunnlag ",
    ASK_TERMS_20_E: "- Motteke utbytte ",
    ASK_TERMS_20_F: "- Skattepliktig utbytte",
    ASK_TERMS_20_G: "- Kontantbehaldning",
    ASK_TERMS_20_H: "- Uttak frå kontoen i løpet av skattleggingsperioden",
    ASK_TERMS_20_I: "- Berekna skjermingsfrådrag i skattleggingsperioden",
    ASK_TERMS_20_J: "- Skattepliktig gevinst/tap ved uttak på kontoen i løp av skattleggingsperioden ",
    ASK_TERMS_20_K: "- Kontoens marknadssverdi (formuesverdi) ved årsskiftet",
    ASK_TERMS_20_L:
      "Ved inngåing av avtalen om Aksjesparekonto gir Kunden Føretaket fullmakt til å innhente opplysningar som trengs for riktig skatterapportering hos skattemyndigheiter, forvaltningsselskap for aksjefond og eventuelle relevante organ eller føretak.",

    ASK_TERMS_21: "7. Flytting av Aksjesparekonto",
    ASK_TERMS_22:
      "Kunden kan flytte aksjesparekontoen sin helt eller delvis til anna Tilbyder etter oppmoding frå Kunden. Føretaket er då plikta til å overføre all relevant informasjon av skattemessig betydning til Tilbydar. Ved Kundens oppmoding om flytting, ser ein det som at Kunden har samtykka i at all relevant informasjon overførast til Tilbyder. Føretaket vil ved flyttebegjæring overføre verdipapir, kontantbehaldning og nødvendige skatteopplysningar innan 10 dagar etter at oppmodinga om flytting er mottatt. Om fristen for oppgjeret for salet av finansielle instrumenter er lenger enn 10 dagar, vil overførsel skje innan 5 dagar etter oppgjersfristen for salet.",

    ASK_TERMS_23: "8. Opphøyr av Aksjesparekonto",
    ASK_TERMS_24:
      "Kunden kan be om at Aksjesparekontoen blir avslutta. Føretaket vil ved krav frå Kunden om opphøyr - etter kunden sin instruks - realisere finansielle instrument på Aksjesparekontoen eller overføre de finansielle instrumenta til relevante Kontoar som kunden oppgjer.",

    ASK_INFO_LINK: "Informasjon om aksjesparekonto",
    ASK_INFO_HEADER: "Relevante skattereglar",
    ASK_INFO_1:
      "Skattefordelane ved aksjesparekonto inneberer at du kan bytte aksjefond og aksjar utan at det utløyser skatt med ein gong. Skatten utløysast først når gevinsten takast ut av Aksjesparekontoen. Du kan derfor ta ut opp til opprinneleg investert beløp utan å realisere skatt på gevinsten.",
    ASK_INFO_2:
      "Dersom du realiserer tap vil du ikkje få frådrag for tapet utan å avslutte Aksjesparekontoen. Du oppnår framleis skjermingsfrådrag på midlar på aksjesparekontoen.",
    ASK_INFO_3: "Kva midlar kan inngå på kontoen?",
    ASK_INFO_4:
      "Du kan eige norske børsnoterte aksjar og eigenkapitalbevis, og norske aksjefond med ein aksjedel på over 80%. Du kan også oppbevare kontantar på aksjesparekonto, men hugs at det ikkje betalast renter på kontantar på aksjesparekonto.",

    ASK_INFO_5: "Renter",
    ASK_INFO_6:
      "Etter gjeldande regler er det ikkje lov å utbetale renter på kontantinnskot på aksjesparekonto. Eventuelle kontantar på aksjesparekonto vil difor ikkje vere renteberande.",

    ASK_INFO_7: "Kostnader",
    ASK_INFO_8: "Kostnader forbundet med aksjesparekonto finn du i ",
    ASK_INFO_8_B: "prislista vår",

    ASK_INFO_9: "Innskotsgarantiordning",
    ASK_INFO_10:
      "Innskotsgarantiordning gjeld kontantinnskot i bank og skal beskytte kunden om banken settast under offentleg administrasjon (går konkurs). Aksjesparekonto er ikkje ein bankkonto og er difor ikkje tilknytt innskotsgarantiordninga.",

    ASK_INFO_11:
      "Dine finansielle instrument på aksjesparekontoen kan bestå av aksjar, eigenkapitalbevis og verdipapirfondsandeler. Aksjar og eigenkapitalbevis vil stå oppført på din VPS-konto. Verdipapirfondsandeler registrerast på Norne sin nominee-konto. Kunden vil til ei kvar tid ha fullt innsyn i sin VPS-konto og behaldning av andelar i verdipapirfond registrert hos Norne. Dine finansielle instrumenter vil haldast utanfor ein eventuell konkurs i Norne og du vil vere sikra tilgang til disse om dette skulle skje. De finansielle instrumenta på nominee-konto er dekka av ",

    ASK_INFO_11_B: "Verdipapirforetaka sine sikringsfond",
    ASK_INFO_11_C: ", som dekker opp til NOK 200.000,- per kunde.",

    ASK_INFO_12:
      "Kontantinnskot på aksjesparekonto vil vere knytt Norne sin klientkonto. Norne pliktar til ei kvar tid å halde kunden sine midlar åtskilt frå Nornes eigne midlar og andre kundar sine midlar ved separat registrering. Klientmidlar vil på denne måten holdast utanfor buet i forbindelse med ein eventuell konkurs i Norne. ",

    ASK_INFO_13: "Bankane sine sikringsfond  ",
    ASK_INFO_14:
      "dekker bankinnskot opp til NOK 2 millionar per kunde dersom banken settast under offentleg administrasjon. For innskot på klientkonto i Nornes namn sjåast Norne som kunde, slik at grensa på NOK 2 millionar gjelder for samla innskot på klientkontoen i banken.",

    ASK_CONFIRM: "Eg godtek vilkåra",
    ASK_BUTTON: "Opprett aksjesparekonto",
    ASK_RECEIPT: "Din Aksjesparekonto er no oppretta.",
    ASK_ERROR_GENERIC: "Noko gikk galt ved oppretting av di Aksjesparekonto.",
    ASK_ERROR_MAX_ACCOUNTS: "Du kan ikkje opprette fleire kontoar.",
    ASK_DEFAULT_NAME: "Aksjesparekonto",
  },
};
